'use client';
import { memo, useEffect, useId, useMemo, useState } from 'react';

import { usePathname, useRouter, useSearchParams } from 'next/navigation';

import { DocumentModalCard } from '@string/components/document-modal-card/document-modal-card';
import { Filter } from '@string/components/filter/filter';
import { Grid } from '@string/components/grid/grid';
import { SecondaryHeroFiles } from '@string/components/secondary-hero-files/secondary-hero-files';
import { useDownload } from '@string/hooks/use-download/use-download';
import type { DocumentPageStoryblok } from '@string/types/generated-storyblok-types';
import type { OverlayDetails } from '@string/types/overlay-details';
import type { FetchStoryblokPagesParams } from '@string/types/search-params';
import { rewriteAssetUrl } from '@string/utils/links/rewrite-asset-url';

import { DocumentListOverlay } from '../document-list-overlay/document-list-overlay';

import styles from './document-list-client.module.scss';

interface DocumentListClientProps {
  title: string;
  index?: number;
  fetchPages: (
    params: FetchStoryblokPagesParams
  ) => Promise<DocumentPageStoryblok[]>;
  placeholder: string;
  downloadLabel: string;
  allFilterLabel: string;
  documentLabel: string;
  basePath: string;
  closeLabel: string;
  filters: string[];
}

export const DocumentListClient: React.FC<DocumentListClientProps> = memo(
  ({
    title,
    closeLabel,
    fetchPages,
    placeholder,
    downloadLabel,
    allFilterLabel,
    basePath,
    filters,
  }) => {
    const uid = useId();
    const [hostname, setHostname] = useState<string>('');
    const searchParams = useSearchParams();
    const pathname = usePathname();
    const router = useRouter();
    const { downloadFiles } = useDownload();
    const [searchInput, setSearchInput] = useState(
      searchParams?.get('search') ?? ''
    );
    const [pages, setPages] = useState<DocumentPageStoryblok[]>([]);

    const [overlay, setOverlay] = useState<OverlayDetails | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const pagesLength = useMemo(
      () => (!loading ? pages?.length : undefined),
      [loading, pages]
    );

    async function handleFetch(params: FetchStoryblokPagesParams) {
      setLoading(true);
      const pages = await fetchPages(params);
      const filteredPages = pages.filter((page) => !page.is_startpage);
      setPages(filteredPages);

      setLoading(false);
    }

    useEffect(() => {
      if (typeof window !== 'undefined' && window.location.href) {
        setHostname(new URL(window.location.href).hostname);
      }
    }, []);

    useEffect(() => {
      const params = {
        search: searchParams?.get('search'),
        tag: searchParams?.get('tag'),
      };
      handleFetch(params);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    const updateSearchParams = (params: Record<string, string>) => {
      const currentParams = new URLSearchParams(searchParams.toString());
      Object.keys(params).forEach((key) => {
        if (params[key]) {
          currentParams.set(key, params[key]);
        } else {
          currentParams.delete(key);
        }
      });
      router.push(`?${currentParams.toString()}`);
    };

    const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
      e.preventDefault();
      const formData = new FormData(e.currentTarget);
      const search = formData.get('search')?.toString() || '';
      updateSearchParams({ search });
    };

    const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      setSearchInput(e.target.value);
    };

    const tag = useMemo(() => searchParams?.get('tag'), [searchParams]);

    function filterMapper(filter: string, i: number) {
      const path = `${basePath}?${new URLSearchParams({
        tag: filter,
      }).toString()}`;
      const key = `DocumentList-${uid}-Filter-${filter}`;
      const isActive = tag === filter;
      return (
        <Filter
          key={key}
          id={`Filter-${filter}`}
          isActive={isActive}
          label={filter}
          path={path}
        />
      );
    }

    function itemMapper(page: any, i: number) {
      if (!page?.content) {
        return null;
      }
      const content: DocumentPageStoryblok = page.content;
      const title = content.title;
      const subTitle = content.sub_title;
      const tag = page.tag_list[0];
      const src = content.image?.filename;
      const alt =
        content.image?.alt ?? content.image?.title ?? content.title ?? '';
      const orientation = (content.image?.orientation as string) ?? 'square';

      const hrefs: string[] = [
        ...new Set(
          [
            ...(content.assets
              ?.map(({ filename }) => filename)
              .filter(Boolean) || []),
            content.document?.filename || '',
            content.external_document || '',
          ]
            .filter(Boolean)
            .map((url) => rewriteAssetUrl(url, hostname))
        ),
      ];
      const details = {
        alt,
        hrefs,
        orientation,
        src,
        title,
      };

      // TODO: Remove this ultra hacky solution for 3d images
      const is3d = pathname.includes('3d-images');
      const figureClassName = is3d ? styles['figure-3d'] : undefined;
      const triggerFigureClassName = is3d
        ? styles['figure-3d-trigger']
        : undefined;

      const key = `DocumentList-${uid}-${page.uuid}`;
      return (
        <DocumentModalCard
          key={key}
          alt={alt}
          description={subTitle}
          figureClassName={figureClassName}
          overlay={details}
          priority={i < 7}
          src={src}
          title={tag}
          triggerFigureClassName={triggerFigureClassName}
        />
      );
    }

    function onDownloadClick(e: React.MouseEvent<HTMLAnchorElement>) {
      e.preventDefault();
      if (!overlay?.hrefs?.length) {
        return;
      }

      downloadFiles(overlay.hrefs);
    }

    return (
      <>
        <SecondaryHeroFiles
          className={styles.search}
          closeLabel={closeLabel}
          count={pagesLength}
          label={placeholder}
          onChange={onChange}
          onSubmit={onSubmit}
          submitLabel={placeholder}
          title={title}
          value={searchInput}
        >
          <Filter
            key={`DocumentList-${uid}-Filter-all_`}
            id="Filter-all"
            isActive={!tag}
            label={allFilterLabel}
            path={pathname}
          />

          {filters.map(filterMapper)}
        </SecondaryHeroFiles>
        <Grid className={styles['grid-container']} columns="6">
          {pages.map(itemMapper)}
          {overlay && (
            <DocumentListOverlay
              downloadLabel={downloadLabel}
              handleOverlay={(value) => setOverlay(value)}
              onDownloadClick={onDownloadClick}
              overlay={overlay}
            />
          )}
        </Grid>
      </>
    );
  }
);
