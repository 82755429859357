'use client';

import { memo, useEffect, useMemo } from 'react';

import { useSearchParams } from 'next/navigation';

import { sendGTMEvent } from '@next/third-parties/google';

import { createCartPayloadFromByo } from '@string/actions/create-cart-payload-from-byo';
import { Iframe } from '@string/components/iframe/iframe';
import { useCart } from '@string/context/cart/use-cart';
import { useLocale } from '@string/hooks/use-locale/use-locale';

interface ByoContainerProps {
  title: string;
  // src: string;
  // createCartPayloadFromByo(locale: SiteLocale, byoItems: ByoItem[], designId: string): Promise<LineItemsAddPayload>;
  // locale: SiteLocale;
}

export const ByoContainer: React.FC<ByoContainerProps> = memo(
  ({
    title,
    // src,
    // createCartPayloadFromByo,
  }) => {
    const locale = useLocale();
    const searchParams = useSearchParams();
    const src = useMemo(() => {
      const url = new URL('https://build-your-own.stringfurniture.com/');
      url.searchParams.set('lan', locale.language);
      url.searchParams.set('countrycode', locale.country_code);
      url.searchParams.set('guid', 'sit_yncNJBpxoNp84Y9sQZkMVvpF');

      const designtemplate = searchParams.get('byo_design_id');

      if (designtemplate) {
        url.searchParams.set('designtemplate', designtemplate);
      }
      return url.href;
    }, [locale, searchParams]);

    const { addByoDesign } = useCart();
    function messageHandler(event: MessageEvent) {
      if (event.data.event === 'byoLoaded') {
        handleLoad(event);
      }
      if (event.data.event === 'cartUpdate') {
        handleAddToCart(event);
      }
      if (event.data.event === 'designUpdate') {
        handleCart(event);
      }
    }

    function handleLoad(_event: MessageEvent) {
      // Send GTM event
      sendGTMEvent({ event: 'ByoLoaded' });

      // Enable BYO cart button
      const iframe = document.querySelector('iframe')?.contentWindow;
      if (iframe) {
        iframe.postMessage(
          {
            data: {},
            event: 'enableCart',
          },
          '*'
        );
      }
    }

    async function handleAddToCart(event: MessageEvent) {
      // Send GTM event
      sendGTMEvent({ event: 'AddToCartFromByo' });

      const designid = event.data.data.designid;
      const byoCart = event.data.data.items;
      const payload = await createCartPayloadFromByo(locale, byoCart, designid);
      await addByoDesign(payload);
    }

    function handleCart(event: MessageEvent) {
      const designid = event.data.data.designid;
      const designname = event.data.data.name;
      const byoCart = event.data.data.items;
      console.info(designid, designname, byoCart);
      // This event is fired every time byo design is updated, however cannot see why we would need to do something here
      // add your code for handling article list changed in BYO
    }

    useEffect(() => {
      window.addEventListener('message', messageHandler, true);
      return () => {
        window.removeEventListener('message', messageHandler, true);
      };
    });

    return <Iframe src={src} title={title} />;
  }
);
