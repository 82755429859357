import { Trigger } from '@radix-ui/react-dialog';
import clsx from 'clsx';

import { getSizes } from '@string/utils/image/get-sizes';

import { StringImage } from '../string-image/string-image';

import styles from './document-modal-trigger.module.scss';

export interface DocumentModalTriggerProps {
  className?: string;
  figureClassName?: string;
  alt: string;
  src: string;
  title: string;
  description?: string;
  priority?: boolean;
}

export const DocumentModalTrigger: React.FC<DocumentModalTriggerProps> = ({
  className,
  title,
  description,
  src,
  alt,
  priority,
  figureClassName,
}) => {
  const sizes = getSizes([
    [0, '50vw'],
    [768, '33vw'],
    [1024, '25vw'],
    [1920, '297px'],
  ]);

  return (
    <Trigger className={clsx(styles.root, className)}>
      <figure className={clsx(styles.figure, figureClassName)}>
        <StringImage
          alt={alt}
          className={styles.image}
          priority={priority}
          sizes={sizes}
          src={src}
          fill
        />
      </figure>
      <span className={styles.details}>
        {title && <p className={clsx('text__body', styles.title)}>{title}</p>}
        {description && (
          <p className={clsx('text__body', styles.description)}>
            {description}
          </p>
        )}
      </span>
    </Trigger>
  );
};
