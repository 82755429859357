'use client';
import { ButtonHTMLAttributes, forwardRef, SVGAttributes } from 'react';

import clsx from 'clsx';

import { CheckSvg, PlusSvg } from '@string/svg/server';
import { ThemeVariant } from '@string/types/theme-variant';

import styles from './hotspot-button.module.scss';

export interface HotspotButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ThemeVariant;
  active?: boolean;
}

const HotspotIcon: React.FC<
  Pick<HotspotButtonProps, 'active'> & SVGAttributes<SVGElement>
> = ({ active, ...props }) => {
  if (active) {
    return <CheckSvg {...props} />;
  }
  return <PlusSvg {...props} />;
};

export const HotspotButton = forwardRef<HTMLButtonElement, HotspotButtonProps>(
  ({ className, variant = 'light', active, ...props }, ref) => (
    <button
      ref={ref}
      className={clsx(
        styles['root'],
        variant === 'dark' && styles['dark'],
        variant === 'light' && styles['light'],
        active && styles['active'],
        className
      )}
      {...props}
    >
      <span className={styles['inner']}>
        <HotspotIcon
          active={active}
          aria-hidden={true}
          className={styles['icon']}
          focusable={false}
          height={20}
          width={20}
        />
      </span>
    </button>
  )
);
