import { useMemo } from 'react';

import clsx from 'clsx';

import styles from './secondary-hero-heading.module.scss';

export interface SecondaryHeroHeadingProps {
  children?: string;
  count?: number | null;
  className?: string;
}

export const SecondaryHeroHeading: React.FC<SecondaryHeroHeadingProps> = ({
  children,
  count: countInput,
  className,
}) => {
  const count = useMemo(
    () => (typeof countInput === 'number' ? `(${countInput})` : ''),
    [countInput]
  );
  return (
    <div className={clsx('grid', styles.root, className)}>
      <h1 className={clsx('text__page-header-fluid', styles.title)}>
        {children}
      </h1>
      <span className={clsx('text__page-header-fluid', styles.count)}>
        {count}
      </span>
    </div>
  );
};
