import { useContext } from 'react';

import { HeaderContext, HeaderContextValue } from './header-context';

export const useHeader = (): HeaderContextValue => {
  const context = useContext(HeaderContext);
  if (!context) {
    throw new Error('useHeader must be used within HeaderContext!');
  }
  return context;
};
