import { HTMLAttributes } from 'react';

import clsx from 'clsx';

import type { GridColumnType } from '@string/types';

import styles from './grid.module.scss';

interface GridProps extends HTMLAttributes<HTMLDivElement> {
  columns?: GridColumnType;
  children: React.ReactNode;
  className?: string;
}

export const Grid: React.FC<GridProps> = ({
  columns,
  children,
  className,
  ...props
}) => {
  return (
    <div
      className={clsx(
        styles['root'],
        columns &&
          ['2', '3', '4', '6'].includes(columns) &&
          styles[`grid-${columns}`],
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};
