'use client';

import {
  createContext,
  Dispatch,
  memo,
  SetStateAction,
  useMemo,
  useState,
} from 'react';

export interface ModalContextValue {
  open: boolean | undefined;
  setOpen(open: boolean | undefined): void;
}

export const ModalContext = createContext<ModalContextValue | null>(null);

interface ModalProviderProps {
  children?: React.ReactNode;
}

export const ModalProvider: React.FC<ModalProviderProps> = memo(
  ({ children }) => {
    const [open, setOpen] = useState<boolean | undefined>(undefined);

    const setOpen2: Dispatch<SetStateAction<boolean | undefined>> = (input) => {
      setOpen(input);
    };
    const value = useMemo(() => {
      return {
        open,
        setOpen: setOpen2,
      };
    }, [open]);

    return (
      <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
    );
  }
);
