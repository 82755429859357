'use client';

import { useEffect, useMemo, useState } from 'react';

import Link from 'next/link';

import clsx from 'clsx';

import { useLocale } from '@string/hooks/use-locale/use-locale';
import { ProductLinkData } from '@string/types';
import { getSizes } from '@string/utils/image/get-sizes';

import { StringImage } from '../string-image/string-image';

import styles from './client-product-card.module.scss';

const revalidate = 3600;

interface ClientProductCardProps {
  path: string;
  className?: string;
  figureClassName?: string;
  style?: React.CSSProperties;
}

export const ClientProductCard: React.FC<ClientProductCardProps> = ({
  path,
  className,
  figureClassName,
  style,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [product, setProduct] = useState<ProductLinkData | null>(null);
  const locale = useLocale();
  const endpoint = useMemo(() => {
    if (!path) {
      return '';
    }
    return `/api/${locale.localeTag}/product/${path.replace(/^\//, '')}`;
  }, [path]);

  async function fetchProduct() {
    try {
      setLoading(true);
      const response = await fetch(endpoint, { next: { revalidate } });
      const data: ProductLinkData = await response.json();
      setProduct(data);
    } catch (error) {
      console.error("Couldn't fetch product", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (endpoint) {
      fetchProduct();
    }
  }, [endpoint]);

  if (!product) {
    return (
      <article
        className={clsx(styles.root, loading && styles.loading, className)}
        style={style}
      >
        <figure className={clsx(styles.figure, figureClassName)}>
          <div className={clsx(styles.image, styles.placeholder)} />
        </figure>

        <div className={clsx('text__body', styles.info)}>
          <div className={styles.title}>
            <span className={styles.placeholder} />
          </div>
          <div className={styles.price}>
            <span
              className={styles.placeholder}
              style={{ '--inline-size': '50%' } as React.CSSProperties}
            />
          </div>
        </div>
      </article>
    );
  }

  const sizes = getSizes([
    [0, '50vw'],
    [768, '25vw'],
    [1920, '455px'],
  ]);

  return (
    <Link
      className={clsx(styles.root, className)}
      href={product.href || ''}
      style={style}
    >
      <figure className={clsx(styles.figure, figureClassName)}>
        {!!product.image && (
          <StringImage
            alt={product.image.alt || ''}
            className={styles.image}
            sizes={sizes}
            src={product.image.url || ''}
            fill
          />
        )}
      </figure>
      <div className={clsx('text__body', styles.info)}>
        <div className={styles.title}>{product.title}</div>
        <div className={styles.price}>{product.price}</div>
      </div>
    </Link>
  );
};
