import clsx from 'clsx';

import styles from './secondary-hero-filters.module.scss';

interface SecondaryHeroFiltersProps {
  children?: React.ReactNode;
  className?: string;
}

export const SecondaryHeroFilters: React.FC<SecondaryHeroFiltersProps> = ({
  children,
  className,
}) => {
  return (
    <div className={clsx('border', styles.root, className)} tabIndex={-1}>
      <div className={clsx(styles.inner)} tabIndex={-1}>
        {children}
      </div>
    </div>
  );
};
