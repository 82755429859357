export const marketVats = new Map<string, number>([
  ['ad', 0],
  ['ae', 0],
  ['ar', 0],
  ['at', 0.2],
  ['au', 0.1],
  ['be', 0.21],
  ['bg', 0.2],
  ['br', 0],
  ['by', 0.2],
  ['ca', 0],
  ['ch', 0.081],
  ['cl', 0],
  ['cn', 0.13],
  ['cy', 0.19],
  ['cz', 0.21],
  ['de', 0.19],
  ['dk', 0.25],
  ['ee', 0.22],
  ['es', 0.21],
  ['fi', 0.24],
  ['fo', 0.25],
  ['fr', 0.2],
  ['gb', 0.2],
  ['gr', 0.23],
  ['gtm', 0],
  ['hk', 0],
  ['hr', 0.25],
  ['hu', 0.27],
  ['id', 0],
  ['ie', 0.23],
  ['il', 0],
  ['in', 0],
  ['is', 0.24],
  ['it', 0.22],
  ['jp', 0.1],
  ['ko', 0.1],
  ['kz', 0],
  ['lt', 0.21],
  ['lu', 0.17],
  ['lv', 0.21],
  ['ma', 0],
  ['mc', 0],
  ['mt', 0.18],
  ['mx', 0],
  ['my', 0],
  ['nl', 0.21],
  ['no', 0.25],
  ['nz', 0],
  ['ph', 0],
  ['pl', 0.23],
  ['pt', 0.23],
  ['ro', 0.24],
  ['ru', 0.18],
  ['se', 0.25],
  ['sg', 0],
  ['si', 0.22],
  ['sk', 0.2],
  ['th', 0],
  ['tw', 0.05],
  ['ua', 0.2],
  ['us', 0],
  ['za', 0],
]);
