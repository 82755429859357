'use client';

import { memo, useId } from 'react';

import clsx from 'clsx';

import { DocumentModalCard } from '@string/components/document-modal-card/document-modal-card';
import { Grid } from '@string/components/grid/grid';
import type { MediaItem } from '@string/types/icat-types/media';

import styles from './list.module.scss';

interface ListProps {
  items: MediaItem[] | undefined;
  downloadLabel?: string;
  specificationsLabel?: string;
  filenameLabel?: string;
  portalId?: string;
}

export const List: React.FC<ListProps> = memo(
  ({
    items,
    downloadLabel = 'Download',
    specificationsLabel = 'Specifications',
  }) => {
    const uid = useId();

    function bytesToMegabytes(bytes: number) {
      const megabytes = bytes / 1048576;
      return megabytes.toFixed(2);
    }

    function itemMapper(item: MediaItem, i: number) {
      const src = item.files.original.url;
      const alt = item.name;
      const title = item.name;
      const hrefs = [item.files.original.url];

      const specifications = `${item.mime_type}, ${item.width}x${
        item.height
      }, ${bytesToMegabytes(item.filesize)}MB`;

      const details = {
        alt,
        hrefs,
        orientation: item.orientation,
        specifications,
        src,
        title: item.name,
      };

      const key = `MediaList-${uid}-${item.uuid}`;
      return (
        <DocumentModalCard
          key={key}
          alt={alt}
          downloadLabel={downloadLabel}
          overlay={details}
          priority={i < 7}
          src={src}
          title={title}
        >
          <dl className={clsx('text__body', styles.specifications)}>
            <dt>{specificationsLabel}</dt>
            <dd>{details.specifications}</dd>
          </dl>
        </DocumentModalCard>
      );
    }

    return (
      <Grid className={styles['grid-container']} columns="6">
        {items?.map(itemMapper)}
      </Grid>
    );
  }
);
