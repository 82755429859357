import type { MarketData } from '@string/types';

export const markets: MarketData[] = [
  {
    code: 'ad',
    languages: ['en'],
    name: 'Andorra',
  },
  {
    code: 'ae',
    languages: ['en'],
    name: 'United Arab Emirates',
    shortName: 'UAE',
  },
  // {
  //   code: 'ar',
  //   languages: ['es'],
  //   name: 'Argentina',
  // },
  {
    code: 'at',
    languages: ['de'],
    localName: 'Österreich',
    name: 'Austria',
  },
  {
    code: 'au',
    languages: ['en'],
    name: 'Australia',
  },
  {
    code: 'be',
    languages: ['fr', 'en'],
    localNames: { fr: 'Belgique' },
    name: 'Belgium',
  },
  {
    code: 'bg',
    languages: ['en'],
    name: 'Bulgaria',
  },
  {
    code: 'br',
    languages: ['en'],
    name: 'Brazil',
  },
  // {
  //   code: 'ca',
  //   languages: ['en', 'fr'],
  //   name: 'Canada',
  // },
  {
    code: 'ch',
    languages: ['de', 'fr'],
    localNames: {
      de: 'Schweiz',
      fr: 'Suisse',
    },
    name: 'Switzerland',
  },
  // {
  //   code: 'cl',
  //   languages: ['es'],
  //   name: 'Chile',
  // },
  {
    code: 'cn',
    languages: ['zh'],
    localName: '中华人民共和国',
    name: 'China',
  },
  {
    code: 'cy',
    languages: ['en'],
    name: 'Cyprus',
  },
  {
    code: 'cz',
    languages: ['en'],
    name: 'Czech Republic',
  },
  {
    code: 'de',
    languages: ['de'],
    localName: 'Deutschland',
    name: 'Germany',
  },
  {
    code: 'dk',
    languages: ['en'],
    name: 'Denmark',
  },
  {
    code: 'ee',
    languages: ['en'],
    name: 'Estonia',
  },
  {
    code: 'es',
    languages: ['es'],
    localName: 'España',
    name: 'Spain',
  },
  {
    code: 'fi',
    languages: ['en'],
    name: 'Finland',
  },
  {
    code: 'fo',
    languages: ['en'],
    name: 'Faroe Islands',
  },
  {
    code: 'fr',
    languages: ['fr'],
    name: 'France',
  },
  {
    code: 'gb',
    languages: ['en'],
    name: 'United Kingdom',
    shortName: 'UK',
  },
  {
    code: 'gr',
    languages: ['en'],
    name: 'Greece',
  },
  // {
  //   code: 'gt',
  //   languages: ['es'],
  //   name: 'Guatemala',
  // },
  // {
  //   code: 'hk', languages: ['en', 'zh'], localNames: { zh: '香港' }, name: 'Hong Kong',
  // },
  {
    code: 'hr',
    languages: ['en'],
    name: 'Croatia',
  },
  {
    code: 'hu',
    languages: ['en'],
    name: 'Hungary',
  },
  {
    code: 'id',
    languages: ['en'],
    name: 'Indonesia',
  },
  {
    code: 'ie',
    languages: ['en'],
    name: 'Ireland',
  },
  // {
  //   code: 'il',
  //   languages: ['en'],
  //   name: 'Israel',
  // },
  {
    code: 'in',
    languages: ['en'],
    name: 'India',
  },
  {
    code: 'is',
    languages: ['en'],
    name: 'Iceland',
  },
  {
    code: 'it',
    languages: ['en'],
    name: 'Italy',
  },
  {
    code: 'jp',
    languages: ['ja'],
    localName: '日本国',
    name: 'Japan',
  },
  {
    code: 'kr',
    languages: ['ko'],
    localName: '대한민국',
    name: 'South Korea',
  },
  // {
  //   code: 'kz',
  //   languages: ['en'],
  //   name: 'Kazakhstan',
  // },
  {
    code: 'lt',
    languages: ['en'],
    name: 'Lithuania',
  },
  {
    code: 'lu',
    languages: ['fr'],
    localName: 'Lëtzebuerg',
    name: 'Luxembourg',
  },
  {
    code: 'lv',
    languages: ['en'],
    name: 'Latvia',
  },
  // {
  //   code: 'ma',
  //   languages: ['en'],
  //   name: 'Morocco',
  // },
  // {
  //   code: 'mc',
  //   languages: ['fr'],
  //   name: 'Monaco',
  // },
  // {
  //   code: 'mt',
  //   languages: ['en'],
  //   name: 'Malta',
  // },
  // {
  //   code: 'mx',
  //   languages: ['es'],
  //   localName: 'México',
  //   name: 'Mexico',
  // },
  {
    code: 'my',
    languages: ['en'],
    name: 'Malaysia',
  },
  {
    code: 'nl',
    languages: ['fr', 'en'],
    localNames: { fr: 'Pays-Bas' },
    name: 'Netherlands',
  },
  {
    code: 'no',
    languages: ['en'],
    name: 'Norway',
  },
  {
    code: 'nz',
    languages: ['en'],
    name: 'New Zealand',
  },
  {
    code: 'ph',
    languages: ['en'],
    name: 'Philippines',
  },
  {
    code: 'pl',
    languages: ['en'],
    name: 'Poland',
  },
  {
    code: 'pt',
    languages: ['en'],
    name: 'Portugal',
  },
  {
    code: 'ro',
    languages: ['en'],
    name: 'Romania',
  },
  {
    code: 'se',
    languages: ['sv'],
    localName: 'Sverige',
    name: 'Sweden',
  },
  {
    code: 'sg',
    languages: ['en', 'zh'],
    localNames: { zh: '新加坡共和国' },
    name: 'Singapore',
  },
  {
    code: 'si',
    languages: ['en'],
    name: 'Slovenia',
  },
  {
    code: 'sk',
    languages: ['en'],
    name: 'Slovakia',
  },
  // {
  //   code: 'th',
  //   languages: ['en'],
  //   name: 'Thailand',
  // },
  {
    code: 'tw',
    languages: ['en'],
    name: 'Taiwan',
  },
  {
    code: 'ua',
    languages: ['en'],
    name: 'Ukraine',
  },
  {
    code: 'us',
    languages: ['en'],
    name: 'United States of America',
    shortName: 'USA',
  },
  // {
  //   code: 'za',
  //   languages: ['en'],
  //   name: 'South Africa',
  // },
] as const;
