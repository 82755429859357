'use client';
import type {
  OnLoadingComplete,
  PlaceholderValue,
  StaticImport,
} from 'next/dist/shared/lib/get-img-props';
import Image from 'next/image';

import { getImageLoader } from '@string/utils/image/get-loader';
import { srcIsSvg } from '@string/utils/image/src-is-svg';

type StringImageProps = Omit<
  React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >,
  'height' | 'width' | 'loading' | 'alt' | 'src' | 'srcSet'
> & {
  src: string | StaticImport;
  alt: string;
  width?: number | `${number}` | undefined;
  height?: number | `${number}` | undefined;
  fill?: boolean | undefined;
  // loader?: ImageLoader | undefined;
  quality?: number | `${number}` | undefined;
  priority?: boolean | undefined;
  loading?: 'eager' | 'lazy' | undefined;
  placeholder?: PlaceholderValue | undefined;
  blurDataURL?: string | undefined;
  unoptimized?: boolean | undefined;
  overrideSrc?: string | undefined;
  onLoadingComplete?: OnLoadingComplete | undefined;
  layout?: string | undefined;
  objectFit?: string | undefined;
  objectPosition?: string | undefined;
  lazyBoundary?: string | undefined;
  lazyRoot?: string | undefined;
};
export const StringImage: React.FC<StringImageProps> = ({
  alt,
  className,
  unoptimized: unoptimizedInput,
  quality = 85,
  ...props
}) => {
  const isSvg = srcIsSvg(props.src);
  const loader = !isSvg ? getImageLoader(props.src) : undefined;
  const unoptimized =
    typeof unoptimizedInput !== 'undefined' ? unoptimizedInput : isSvg;

  return (
    <Image
      alt={alt || ''}
      className={className}
      loader={loader}
      quality={quality}
      unoptimized={unoptimized}
      {...props}
    />
  );
};
