'use client';
import { memo } from 'react';

import { Accordion } from 'react-accessible-accordion';
import { render } from 'storyblok-rich-text-react-renderer';

import { AccordionItemString } from '@string/components/accordion-item-string/accordion-item-string';
import type { ArticleStoryblok } from '@string/types/generated-storyblok-types';

import styles from './items.module.scss';

interface ItemsProps {
  items: ArticleStoryblok[];
}

export const Items: React.FC<ItemsProps> = memo(({ items }) => {
  const itemMapper = ({
    title,
    label,
    description,
    _uid,
  }: ArticleStoryblok) => {
    const key = `AccordionItem-${_uid}`;
    return (
      <AccordionItemString key={key} label={label} title={title ?? ''}>
        <div className={styles.item}>{render(description)}</div>
      </AccordionItemString>
    );
  };
  return (
    <Accordion allowMultipleExpanded allowZeroExpanded>
      {items.map(itemMapper)}
    </Accordion>
  );
});
