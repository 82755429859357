'use client';
import { memo, useEffect, useMemo, useState } from 'react';

import Link from 'next/link';
import { useSearchParams } from 'next/navigation';

import clsx from 'clsx';

import { Filter } from '@string/components/filter/filter';
import { Grid } from '@string/components/grid/grid';
import { ProductListHeader } from '@string/components/product-list-header/product-list-header';
import { SecondaryHeroHeading } from '@string/components/secondary-hero-heading/secondary-hero-heading';
import { StringImage } from '@string/components/string-image/string-image';
import type { DocumentPageStoryblok } from '@string/types/generated-storyblok-types';
import type { FetchStoryblokPagesParams } from '@string/types/search-params';
import type { SiteLocale } from '@string/types/site';
import { getSizes } from '@string/utils/image/get-sizes';

import styles from './journal-list-client.module.scss';

interface JournalListProps {
  title: string;
  index?: number;
  fetchPages: (
    params: FetchStoryblokPagesParams
  ) => Promise<DocumentPageStoryblok[]>;
  basePath: string;
  filters: string[];
  locale: SiteLocale;
}

export const JournalListClient: React.FC<JournalListProps> = memo(
  ({ title, index, fetchPages, basePath, filters, locale }) => {
    const searchParams = useSearchParams();
    const [pages, setPages] = useState<DocumentPageStoryblok[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const pagesLength = useMemo(
      () => (!loading ? pages?.length : undefined),
      [loading, pages]
    );

    async function handleFetch(params: FetchStoryblokPagesParams) {
      setLoading(true);
      const pages = await fetchPages(params);
      const filteredPages = pages.filter((page) => !page.is_startpage);
      setPages(filteredPages);
      setLoading(false);
    }

    useEffect(() => {
      const params = {
        search: searchParams?.get('search'),
        tag: searchParams?.get('tag'),
      };
      handleFetch(params);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    function filterMapper(filter: string, i: number) {
      const path = `${basePath}?${new URLSearchParams({
        tag: filter,
      }).toString()}`;

      const tag = searchParams?.get('tag');
      const isActive = tag === filter;
      return (
        <Filter
          key={`Filter-${filter}-${i}`}
          id={`Filter-${filter}`}
          isActive={isActive}
          label={filter}
          path={path}
          // aria-controls={} // TODO
        />
      );
    }

    function itemMapper(item: any, i: number) {
      if (!item?.content) {
        return null;
      }
      const { title, image } = item.content;
      const { tag_list } = item;

      const tags = tag_list.map((tag: any) => tag.name).join(', ');

      const classMap = ['item__m', 'item__s', 'item__l'];
      const rowIndex = Math.floor(i / 3);
      const positionInRow = i % 3;
      const adjustedIndex = (3 + positionInRow - (rowIndex % 3)) % 3;
      const className = classMap[adjustedIndex];
      const src = image.filename;
      const alt = image.alt ?? image.title ?? title;
      const parts = item.full_slug?.replace(/(^\/|\/$)/, '').split('/');
      if (/^[a-z]{2,3}$/.test(parts?.[0])) {
        parts.shift();
      }
      const sizes = getSizes([
        [0, '50vw'],
        [768, '25vw'],
        [1920, '455px'],
      ]);
      return (
        <Link
          key={`Article-${item.uuid}`}
          className={clsx(styles.item, styles[className])}
          href={`/${[locale.localeTag, ...parts].join('/')}`}
        >
          <figure
            className={clsx(
              styles[`${className}--figure`],
              styles.item__figure
            )}
          >
            <StringImage
              alt={alt}
              className={styles.item__image}
              sizes={sizes}
              src={src}
              fill
            />
          </figure>
          <div>
            <p className={clsx(styles.item__label, 'text__body')}>{tags}</p>
            <h3 className={clsx(styles.item__title, 'text__body')}>{title}</h3>
          </div>
        </Link>
      );
    }

    return (
      <>
        <SecondaryHeroHeading count={pagesLength}>{title}</SecondaryHeroHeading>
        <ProductListHeader>
          <div aria-labelledby={title} role="tablist">
            {filters.map(filterMapper)}
          </div>
        </ProductListHeader>
        <Grid className={clsx(styles.grid, 'block-spacing__sm')} columns="4">
          {pages.map(itemMapper)}
        </Grid>
      </>
    );
  }
);
