'use client';
import { memo, useId, useMemo, useRef } from 'react';

import Link from 'next/link';

import clsx from 'clsx';

import {
  PropsWithOptions,
  ToggleOption,
} from '@string/types/props-with-options';
import { inGroupNavigation } from '@string/utils/a11y/in-group-navigation';

import styles from './button-switch.module.scss';

export interface ButtonSwitchOption {
  id: string;
  label: string;
  value: string;
}

export interface ButtonSwitchProps extends PropsWithOptions {
  label?: string;
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
}

export const ButtonSwitch: React.FC<ButtonSwitchProps> = memo(
  ({ className, style, options, disabled, selected }) => {
    const uid = useId();
    const name = useMemo(() => `ButtonSwitch-${uid}`, [uid]);

    const ref = useRef<HTMLDivElement>(null);

    // Exit early if there are no options
    if (!options || options?.length === 0) {
      return null;
    }

    const optionsMapper = (option: ToggleOption, i: number) => {
      if (!option.datasource || !option.href || !option.name) {
        return null;
      }
      const key = `ButtonSwitch-option-${name}-${uid}-${i}`;

      return (
        <Link
          key={key}
          className={clsx(
            'text__navigation',
            styles.option,
            selected === option.name && styles.selected,
            disabled && styles.disabled
          )}
          href={option.href || ''}
          id={key}
          onKeyDown={inGroupNavigation}
        >
          {option.datasource.value}
        </Link>
      );
    };

    return (
      <div
        ref={ref}
        className={clsx('text__small', styles['root'], className)}
        style={style}
        tabIndex={-1}
      >
        {options.map(optionsMapper)}
      </div>
    );
  }
);
