import clsx from 'clsx';

import {
  SecondaryHeroHeading,
  SecondaryHeroHeadingProps,
} from '../secondary-hero-heading/secondary-hero-heading';

import styles from './secondary-hero.module.scss';

export interface SecondaryHeroProps
  extends Omit<SecondaryHeroHeadingProps, 'className' | 'children'> {
  children?: React.ReactNode;
  title?: string;
  className?: string;
  rootClassName?: string;
  headingClassName?: string;
  noBorder?: boolean;
}

export const SecondaryHero: React.FC<SecondaryHeroProps> = ({
  title,
  count,
  children,
  className,
  headingClassName,
  rootClassName,
  noBorder,
}) => {
  return (
    <section className={clsx(styles.root, rootClassName)}>
      <SecondaryHeroHeading className={headingClassName} count={count}>
        {title}
      </SecondaryHeroHeading>
      <div
        className={clsx(
          'container',
          !noBorder && 'border',
          styles.body,
          className
        )}
      >
        {children}
      </div>
    </section>
  );
};
