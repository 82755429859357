import { NextRequest } from 'next/server';

import { i18nConfig } from './i18n-config';

// Regex pattern to find locale in pathname
const localeRegex = /^\/([a-z]{2,3}-[a-z]{2,3})/i;

export function getLocaleTagFromPathname(pathname: string): string | undefined {
  return localeRegex.exec(pathname)?.[1]?.toLowerCase();
}

export function getLocaleFromPathname(pathname: string): string | null {
  // Exit early if no locale is found
  if (!localeRegex.test(pathname)) {
    return null;
  }

  // Extract locale from pathname
  const locale = getLocaleTagFromPathname(pathname);

  // Return found locale if valid
  if (locale && i18nConfig.locales.includes(locale)) {
    return locale;
  }

  // Exit if all else fails
  return null;
}

export function getLocaleFromRequest(request: NextRequest) {
  const localeFromPath = getLocaleFromPathname(new URL(request.url).pathname);
  if (localeFromPath) {
    return localeFromPath.toLowerCase();
  }
  return i18nConfig.defaultLocale.toLowerCase();
}
