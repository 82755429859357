import type { Price } from '@string/types';

import { normalizeLocale } from '../normalize-locale/normalize-locale';

export function formatPrice(price: Price | null | undefined, locale: string) {
  const normalizedLocale = normalizeLocale(locale);
  const pattern = /^\w+-\w+$/i;
  const value = price?.price_inc_vat;
  if (
    !price?.currency?.web_symbol ||
    !value ||
    !normalizedLocale ||
    !pattern.test(normalizedLocale)
  ) {
    return null;
  }

  return new Intl.NumberFormat(normalizedLocale, {
    currency: price.currency.name,
    currencyDisplay: 'code',
    style: 'currency',
  }).format(value);
}
