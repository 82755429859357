import { AnchorHTMLAttributes, ButtonHTMLAttributes } from 'react';

import Link from 'next/link';

import clsx from 'clsx';

import { ArrowRightSvg } from '@string/svg';
import type { ThemeVariant } from '@string/types';

import styles from './button-slider.module.scss';

type ButtonSliderDirection = 'prev' | 'next';

type ButtonOrAnchorAttributes =
  | ButtonHTMLAttributes<HTMLButtonElement>
  | AnchorHTMLAttributes<HTMLAnchorElement>;

interface ButtonSliderPropsBase {
  theme?: ThemeVariant;
  direction?: ButtonSliderDirection;
}

export type ButtonSliderButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  ButtonSliderPropsBase;
export type ButtonSliderAnchorProps = AnchorHTMLAttributes<HTMLAnchorElement> &
  ButtonSliderPropsBase;

const ButtonSliderContainer: React.FC<
  ButtonSliderButtonProps | ButtonSliderAnchorProps
> = (props) => {
  if ('href' in props) {
    const { href = '', ...rest } = props;
    return <Link href={href} {...rest} />;
  }
  return <button {...(props as ButtonSliderButtonProps)} />;
};

export const ButtonSlider: React.FC<
  ButtonSliderButtonProps | ButtonSliderAnchorProps
> = ({ className, theme = 'dark', direction = 'next', ...props }) => {
  return (
    <ButtonSliderContainer
      className={clsx(
        'text__small',
        styles['root'],
        theme === 'dark' && styles['dark'],
        theme === 'light' && styles['light'],
        direction === 'prev' && styles['prev'],
        direction === 'next' && styles['next'],
        className
      )}
      data-direction={direction}
      {...props}
    >
      <ArrowRightSvg
        aria-hidden={true}
        className={clsx('icon__md', styles['icon'])}
        focusable={false}
      />
    </ButtonSliderContainer>
  );
};
