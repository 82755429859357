import type { AssetStoryblok } from '@string/types/generated-storyblok-types';

import { srcIsStoryblok } from '../image/storyblok-loader';

type ImageFormat = 'jpeg' | 'png' | 'webp' | 'avif';

interface StoryblokImageResize {
  width: number;
  height?: number;
}

interface StoryblokImageOptions {
  resize?: StoryblokImageResize;
  format?: ImageFormat;
  quality?: number;
  smart?: boolean;
  focus?: string;
}

const STORYBLOK_SIZE_LIMIT = 4000 as const;

function getStoryblokImageSize(
  resize: StoryblokImageResize
): string | undefined {
  if (!resize.width) {
    return;
  }

  let { width, height = 0 } = resize;

  if (width > height && width > STORYBLOK_SIZE_LIMIT) {
    width = STORYBLOK_SIZE_LIMIT;
    height = resize.height
      ? Math.floor((resize.height / resize.width) * width)
      : 0;
  }
  if (resize.height && height > width && height > STORYBLOK_SIZE_LIMIT) {
    height = STORYBLOK_SIZE_LIMIT;
    width = Math.floor((resize.width / resize.height) * height);
  }
  return `${width}x${height}`;
}

function getStoryblokImageQuality(input: number): string {
  const quality = Math.floor(Math.max(0, Math.min(100, input)));
  return `quality(${quality})`;
}

interface ParsedStoryblokImageSrc {
  filename: string;
  quality?: number;
  format?: ImageFormat;
  focus?: string;
  resize?: StoryblokImageResize;
}

export function parseStoryblokImageSrc(src: string): ParsedStoryblokImageSrc {
  const url = new URL(src);
  const parts = url.pathname.split('/');
  const path = parts.slice(0, 6).join('/');
  const filename = new URL(path, url).href.replace(/\/m\b\/?.*$/, '');
  const size = /^\d+x\d+$/.test(parts[parts.indexOf('m') + 1])
    ? parts[parts.indexOf('m') + 1]
    : undefined;
  let resize: StoryblokImageResize | undefined;
  if (size) {
    const [width, height] = size.split('x');
    resize = {
      height: parseInt(height, 10),
      width: parseInt(width, 10),
    };
  }
  const filters = parts
    .find((part) => part.startsWith('filters:'))
    ?.substring(8)
    .replace(/:([a-z])/, '__$1')
    .split('__');
  const qualityFilter = filters?.find((filter) => filter.startsWith('quality'));
  const focalFilter = filters?.find((filter) => filter.startsWith('focal'));
  const formatFilter = filters?.find((filter) => filter.startsWith('format'));
  const quality = qualityFilter
    ? parseInt(qualityFilter?.replace(/quality\((.+)\)/, '$1'), 10)
    : undefined;
  const format = formatFilter
    ? (formatFilter?.replace(/format\((.+)\)/, '$1') as ImageFormat)
    : undefined;
  const focus = focalFilter
    ? focalFilter?.replace(/focal\((.+?)\)/, '$1')
    : undefined;

  return {
    filename,
    focus,
    format,
    quality,
    resize,
  };
}

function getStoryblokImageFilters(
  options?: StoryblokImageOptions
): string | undefined {
  const filters: string[] = [];

  if (options?.format) {
    filters.push(`format(${options.format})`);
  }
  if (typeof options?.quality === 'number') {
    filters.push(getStoryblokImageQuality(options.quality));
  }
  if (options?.resize && options?.focus) {
    filters.push(`focal(${options.focus})`);
  }
  return filters.length
    ? 'filters:' + filters.filter(Boolean).join(':')
    : undefined;
}

function getStoryblokImageFilename(
  image: AssetStoryblok | string | undefined,
  optionsInput?: StoryblokImageOptions
) {
  let filename = '';
  const focus = typeof image === 'string' ? undefined : image?.focus;
  const options = {
    ...(optionsInput || {}),
    focus: typeof image === 'string' ? undefined : image?.focus,
  };
  if (typeof image === 'string') {
    const parsed = parseStoryblokImageSrc(image);
    filename = parsed.filename;
    options.focus = focus || parsed.focus;
    options.format = options?.format || parsed.format;
    options.quality = options?.quality || parsed.quality;
    options.resize = options?.resize || parsed.resize;
  }
  if (typeof image !== 'string' && image?.filename) {
    filename = image?.filename;
  }

  return {
    filename,
    options,
  };
}

export function getStoryblokImageSrc(
  image: AssetStoryblok | string | undefined,
  optionsInput?: StoryblokImageOptions
): string | undefined {
  if (typeof image !== 'string' && !image?.filename) {
    return;
  }
  if (typeof image === 'string' && !srcIsStoryblok(image)) {
    return image;
  }

  const { filename, options } = getStoryblokImageFilename(image, optionsInput);

  if (!filename) {
    return;
  }

  const parts: (string | undefined)[] = [filename, 'm'];
  if (options?.resize) {
    parts.push(getStoryblokImageSize(options.resize));
  }

  if ((options?.resize || options?.focus) && options?.smart !== false) {
    parts.push('smart');
  }

  parts.push(getStoryblokImageFilters({ ...options }));

  return parts.filter(Boolean).join('/');
}
