'use client';
import { memo, useEffect, useMemo, useRef, useState } from 'react';

import clsx from 'clsx';
import useScroll from 'react-use/lib/useScroll';
import useWindowSize from 'react-use/lib/useWindowSize';

import type { ThemeVariant } from '@string/types/theme-variant';

import { ButtonSlider } from '../button-slider/button-slider';
import { ButtonTextLink } from '../button-text-link/button-text-link';

import styles from './base-carousel.module.scss';

export interface BaseCarouselProps {
  title?: React.ReactNode;
  titleLink?: string;
  prevLabel?: string;
  nextLabel?: string;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  headClassName?: string;
  headStyle?: React.CSSProperties;
  listClassName?: string;
  listStyle?: React.CSSProperties;
  flush?: boolean;
  block?: boolean;
  theme?: ThemeVariant;
}

const disabledMargin = 12 as const;

export const BaseCarousel: React.FC<BaseCarouselProps> = memo(
  ({
    title,
    titleLink,
    prevLabel,
    nextLabel,
    children,
    className,
    style,
    headClassName,
    headStyle,
    listClassName,
    listStyle,
    flush,
    block,
    theme,
    ...rest
  }) => {
    const listRef = useRef<HTMLDivElement>(null);
    const { x } = useScroll(listRef);
    const { width, height } = useWindowSize();
    const [nextDisabled, setNextDisabled] = useState(false);
    const prevDisabled = useMemo(() => x < disabledMargin, [x, disabledMargin]);
    const hideControls = useMemo(
      () => prevDisabled && nextDisabled,
      [prevDisabled, nextDisabled]
    );

    useEffect(() => {
      setNextDisabled((oldValue) => {
        if (listRef.current) {
          if (listRef.current.scrollWidth <= listRef.current.offsetWidth) {
            return true;
          }
          return (
            x >=
            listRef.current.scrollWidth -
              listRef.current.offsetWidth -
              disabledMargin
          );
        }
        return oldValue;
      });
    }, [x, listRef.current, width, height]);

    const onClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
      const list = listRef.current;
      if (list?.clientWidth) {
        let left = list.clientWidth;
        if (event.currentTarget.dataset.direction === 'prev') {
          left = -left;
        }
        list.scrollBy({
          behavior: 'smooth',
          left,
        });
      }
    };

    return (
      <div
        className={clsx(
          styles['root'],
          flush && styles['root--flush'],
          className
        )}
        style={style}
        {...rest}
      >
        <div
          className={clsx(
            styles.head,
            block && styles['head--block'],
            headClassName
          )}
          style={headStyle}
        >
          {titleLink ? (
            <ButtonTextLink href={titleLink ?? ''}>{title}</ButtonTextLink>
          ) : (
            title
          )}
          {!hideControls && (
            <div className={styles['controls']}>
              <ButtonSlider
                aria-label={prevLabel}
                direction="prev"
                disabled={prevDisabled}
                onMouseDown={onClick}
                theme={theme}
              />
              <ButtonSlider
                aria-label={nextLabel}
                disabled={nextDisabled}
                onMouseDown={onClick}
                theme={theme}
              />
            </div>
          )}
        </div>
        <div
          ref={listRef}
          className={clsx(
            'hide-scrollbars',
            styles.list,
            block && styles['list--block'],
            listClassName
          )}
          style={listStyle}
        >
          {children}
        </div>
      </div>
    );
  }
);
