export const inGroupNavigation: React.KeyboardEventHandler = (event) => {
  const target = event.currentTarget;
  if (!target.matches(':focus')) {
    return;
  }

  // Select the option when the user presses enter or space
  if (
    !target.matches('[aria-selected]') &&
    !target.matches('[aria-disabled]') &&
    !target.matches(':disabled') &&
    !target.matches(':checked') &&
    ['Enter', ' '].includes(event.key) &&
    'click' in target &&
    typeof target.click === 'function'
  ) {
    target.click();
    event.preventDefault();
  }

  // Find previous option
  let previousOption: HTMLElement | null = null;
  if (target.previousElementSibling) {
    previousOption = target.previousElementSibling as HTMLElement;
  }
  if (!previousOption && target.parentElement?.lastElementChild) {
    previousOption = target.parentElement.lastElementChild as HTMLElement;
  }
  // Find next option
  let nextOption: HTMLElement | null = null;
  if (target.nextElementSibling) {
    nextOption = target.nextElementSibling as HTMLElement;
  }
  if (!nextOption && target.parentElement?.firstElementChild) {
    nextOption = target.parentElement.firstElementChild as HTMLElement;
  }

  // Go to next option
  if (['ArrowRight', 'ArrowDown'].includes(event.key) && nextOption) {
    nextOption.focus();
    event.preventDefault();
  }
  // Go to previous option
  if (['ArrowLeft', 'ArrowUp'].includes(event.key) && previousOption) {
    previousOption.focus();
    event.preventDefault();
  }
};
