import { useMemo } from 'react';

import type { ThemeVariant } from '@string/types';
import { isDarkTheme } from '@string/utils/theme/is-dark-theme';

type UseTheme = {
  isDark: boolean;
  color: string;
  theme: ThemeVariant;
  themeClassName: string;
  themeStyle: React.CSSProperties;
};
export const useTheme = (background?: string): UseTheme => {
  return useMemo(() => {
    const isDark = isDarkTheme(background);
    const theme = isDark ? 'light' : 'dark';
    const color = isDark ? '#fff' : '#101010';
    const themeClassName = `theme--${theme}`;
    const themeStyle = {
      '--color-link': color,
      '--color-text': color,
      '--local-background-color': background || 'transparent',
      '--local-border-color': color,
      '--local-text-color': color,
    } as React.CSSProperties;
    return {
      color,
      isDark,
      theme,
      themeClassName,
      themeStyle,
    };
  }, [background]);
};
