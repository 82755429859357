'use client';

import * as Dialog from '@radix-ui/react-dialog';
import { Root as VisuallyHidden } from '@radix-ui/react-visually-hidden';
import clsx from 'clsx';

import { ModalOverlay } from '../modal-overlay/modal-overlay';

import styles from './modal.module.scss';

export interface ModalProps extends Dialog.DialogProps {
  children?: React.ReactNode;
  trigger?: React.ReactNode;
  className?: string;
  title?: string;
  description?: string;
  style?: React.CSSProperties;
  close?: React.ReactNode;
}

export const Modal: React.FC<ModalProps> = ({
  children,
  className,
  style,
  trigger,
  title,
  description,
  open,
  defaultOpen,
  modal,
  onOpenChange,
  close,
}) => {
  return (
    <Dialog.Root
      defaultOpen={defaultOpen}
      modal={modal}
      onOpenChange={onOpenChange}
      open={open}
    >
      <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>
      <Dialog.Portal>
        <ModalOverlay className={styles.overlay} />
        <Dialog.Content className={clsx(styles.content)}>
          <div className={clsx(styles.inner, className)} style={style}>
            <VisuallyHidden>
              <Dialog.Title className={styles.title}>{title}</Dialog.Title>
              <Dialog.Description className={styles.description}>
                {description}
              </Dialog.Description>
            </VisuallyHidden>
            {children}
            <Dialog.Close asChild>{close}</Dialog.Close>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
