'use client';
import { ButtonHTMLAttributes, useMemo, useState } from 'react';

import Link from 'next/link';

import { DialogProps } from '@radix-ui/react-dialog';
import clsx from 'clsx';

import { useCart } from '@string/context/cart/use-cart';
import { useModal } from '@string/context/modal/use-modal';
import { HotspotProductData } from '@string/types';
import { Datasources, ProductDetailFields } from '@string/types/datasource';
import { AssetStoryblok } from '@string/types/generated-storyblok-types';
import { SiteLocale } from '@string/types/site';
import { ThemeVariant } from '@string/types/theme-variant';
import { getSizes } from '@string/utils/image/get-sizes';
import { formatPrice } from '@string/utils/price/format-price';

import { ButtonLarge, ButtonLargeLink } from '../button-large/button-large';
import { ButtonTextLink } from '../button-text-link/button-text-link';
import { HotspotButton } from '../hotspot-button/hotspot-button';
import { Modal } from '../modal/modal';
import { ProductDetailImage } from '../product-detail-image/product-detail-image';
import { ProductDetailPrice } from '../product-detail-price/product-detail-price';
import { ResponsiveImage } from '../responsive-image/responsive-image';

import styles from './hotspot.module.scss';

const revalidate = 3600;

export interface HotspotProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    Pick<DialogProps, 'onOpenChange'> {
  variant?: ThemeVariant;
  active?: boolean;
  path: string;
  datasources?: Datasources;
  locale: SiteLocale;
  pdp: ProductDetailFields;
  image?: AssetStoryblok;
}

export const Hotspot: React.FC<HotspotProps> = ({
  className,
  variant,
  active,
  onOpenChange: onOpenChangeInput,
  onMouseEnter: onMouseEnterInput,
  path,
  datasources,
  locale,
  image,
  ...props
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const [data, setData] = useState<HotspotProductData | undefined>(undefined);
  const { open, setOpen } = useModal();
  const closeLabel = useMemo(
    () => datasources?.microcopy.get('hotspot_close') || 'Go back',
    [datasources]
  );
  const label = useMemo(() => {
    return (
      data?.title ||
      datasources?.microcopy.get('hotspot_label_placeholder') ||
      'View product'
    );
  }, [datasources, data]);
  const {
    loading: cartLoading,
    addLineItemToCart,
    priceLabel,
    addToCartLabel,
    addBundleToCart,
    findStoreHref,
  } = useCart();
  const endpoint = useMemo(() => {
    if (!path) {
      return '';
    }
    return `/api/${locale.localeTag}/hotspot/${path.replace(/^\//, '')}`;
  }, [path]);
  const { title, src, price } = useMemo(() => {
    return {
      price: [
        !locale.isShopEnabled && priceLabel,
        formatPrice(data?.price, locale.localeTag) || '',
      ]
        .filter(Boolean)
        .join(' '),
      src: data?.image || data?.product_image?.url || '',
      title: data?.title || '',
    };
  }, [data, locale.localeTag]);
  const href = useMemo(
    () =>
      path ? `/${locale.localeTag}/products/${path.replace(/^\//, '')}` : '',
    [path, locale.localeTag]
  );

  async function fetchProduct() {
    try {
      setLoading(true);
      const response = await fetch(endpoint, { next: { revalidate } });
      const json = await response.json();
      setData(json);
    } catch (error) {
      console.error("Couldn't fetch product details", error);
    } finally {
      setLoading(false);
    }
  }

  // TODO: Check for low bandwidth preference
  const onClickOrEnter: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    onMouseEnterInput?.(event);
    if (!loading && !data) {
      fetchProduct();
    }
  };

  const onOpenChange = (open: boolean) => {
    onOpenChangeInput?.(open);
    if (open && !loading && !data) {
      fetchProduct();
    }
    setOpen(undefined);
  };
  const aspectRatioPortrait = 1;
  const aspectRatioLandscape = 8 / 10;
  const sizes = getSizes([
    [0, '100vw'],
    [768, '50vw'],
    [1920, '773px'],
  ]);

  const onAddToCartClick = () => {
    if (data && 'articles' in data && data.articles?.length) {
      addBundleToCart(data.articles);
      setOpen(false);
    }
    if (data && 'big_commerce' in data && data.big_commerce) {
      addLineItemToCart({
        product_id: data.big_commerce.big_commerce_product_id,
        quantity: 1,
        variant_id: data.big_commerce.big_commerce_id,
      });
      setOpen(false);
    }
  };

  return (
    <Modal
      className={styles.root}
      close={
        <ButtonTextLink className={styles.close}>{closeLabel}</ButtonTextLink>
      }
      onOpenChange={onOpenChange}
      open={open}
      trigger={
        <HotspotButton
          active={active}
          aria-label={label}
          className={className}
          onClick={onClickOrEnter}
          onMouseEnter={onClickOrEnter}
          title={label}
          variant={variant}
          {...props}
        />
      }
    >
      <div className={styles.figure}>
        {image && (
          <ResponsiveImage
            alt={title}
            className={clsx(styles.image)}
            image={image}
            rules={[
              {
                aspectRatio: aspectRatioPortrait,
                orientation: 'portrait',
              },
              {
                aspectRatio: aspectRatioLandscape,
                orientation: 'landscape',
              },
            ]}
            sizes={sizes}
          />
        )}
      </div>
      <div className={styles.details}>
        <ProductDetailImage
          alt={title}
          loading={loading}
          sizes={sizes}
          src={src}
        />
        <Link
          className={clsx(
            'text__sub-header',
            styles.title,
            loading && styles['title--loading']
          )}
          href={href}
        >
          {title}
        </Link>
        <ProductDetailPrice
          className={clsx('text__large', styles.price)}
          loading={loading}
        >
          {price}
        </ProductDetailPrice>
        <div className={styles.actions}>
          {locale.isShopEnabled && (
            <ButtonLarge
              disabled={loading || cartLoading || loading}
              onClick={onAddToCartClick}
              variant="filled"
              wide
            >
              {addToCartLabel}
            </ButtonLarge>
          )}
          {!locale.isShopEnabled && (
            <ButtonLargeLink href={findStoreHref} variant="filled" wide>
              {addToCartLabel}
            </ButtonLargeLink>
          )}
        </div>
      </div>
    </Modal>
  );
};
