export const isDarkTheme = (input?: string) => {
  if (!input) {
    return false;
  }
  let hex = input;

  // Handle short hex codes
  if (input.length === 4) {
    hex =
      '#' +
      input.slice(1, 2) +
      input.slice(1, 2) +
      input.slice(2, 3) +
      input.slice(2, 3) +
      input.slice(3, 4) +
      input.slice(4, 4);
  }

  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  const luminance = 0.299 * r + 0.587 * g + 0.114 * b;

  return luminance < 128;
};
