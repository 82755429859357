'use client';
import { memo, useEffect } from 'react';

declare global {
  interface Window {
    flowbox?: {
      (
        command: string,
        config: {
          container: string;
          key: string;
          locale: string;
        }
      ): void;
      q: any[];
    };
  }
}

const loadFlowbox = () => {
  return new Promise<void>((resolve, reject) => {
    (function (d, id) {
      if (!window.flowbox) {
        // TODO fix errors
        const f = function () {
          // eslint-disable-next-line prefer-rest-params
          f.q.push(arguments);
        };
        f.q = [] as any[];
        window.flowbox = f;
      }
      if (d.getElementById(id)) {
        return resolve();
      }
      const s = d.createElement('script');
      const fjs = d.scripts[d.scripts.length - 1];
      if (!fjs?.parentNode) {
        return reject(new Error('No parent node found'));
      }
      s.id = id;
      s.async = true;
      s.src = 'https://connect.getflowbox.com/flowbox.js';
      s.onload = () => resolve();
      s.onerror = () => reject(new Error('Failed to load script'));
      fjs.parentNode.insertBefore(s, fjs);
    })(document, 'flowbox-js-embed');
  });
};

interface FlowboxContainerProps {
  flowKey: string;
  localeTag: string;
}

export const FlowboxContainer: React.FC<FlowboxContainerProps> = memo(
  ({ flowKey, localeTag }) => {
    const containerId = 'js-flowbox-flow';

    useEffect(() => {
      loadFlowbox()
        .then(() => {
          if (window.flowbox) {
            window.flowbox('init', {
              container: `#${containerId}`,
              key: flowKey,
              locale: localeTag,
            });
          }
        })
        .catch((error) => {
          console.error('Failed to load Flowbox script:', error);
        });
    }, [flowKey, localeTag, containerId]);

    return <div id={containerId} />;
  }
);
