'use client';
import * as Dialog from '@radix-ui/react-dialog';
import clsx from 'clsx';

import { useModal } from '@string/context/modal/use-modal';
import { default as SearchSvg } from '@string/svg/search.svg';

import { IconButton, IconButtonRef } from '../icon-button/icon-button';
import { InputText } from '../input-text/input-text';
import { ModalHeader } from '../modal-header/modal-header';
import { ModalOverlay } from '../modal-overlay/modal-overlay';

import styles from './secondary-hero-search.module.scss';

export interface SecondaryHeroSearchProps {
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  label?: string;
  className?: string;
  submitLabel?: string;
  value?: string;
  closeLabel?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

export const SecondaryHeroSearch: React.FC<SecondaryHeroSearchProps> = ({
  onSubmit: onSubmitInput,
  label,
  submitLabel,
  value,
  onChange,
  closeLabel = 'Close',
  className,
}) => {
  const { open, setOpen } = useModal();

  const onOpenChange = (_open: boolean) => {
    setOpen(undefined);
  };
  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    onSubmitInput?.(e);
    setOpen(false);
  };
  const form = (
    <form className={styles.form} onSubmit={onSubmit}>
      <InputText
        className={styles['input-container']}
        inputClassName={styles.input}
        label={label}
        name="search"
        onChange={onChange}
        placeholder={label}
        value={value}
        simple
      />
      <IconButton
        aria-label={submitLabel}
        className={styles.submit}
        type="submit"
      >
        <SearchSvg
          aria-hidden={true}
          focusable={false}
          height={24}
          width={24}
        />
      </IconButton>
    </form>
  );

  return (
    <div className={clsx('border', styles.root, className)}>
      <div className={styles.mobile}>
        <Dialog.Root onOpenChange={onOpenChange} open={open}>
          <Dialog.Trigger asChild>
            <IconButtonRef aria-label={label} className={styles.trigger}>
              <SearchSvg
                aria-hidden={true}
                focusable={false}
                height={24}
                width={24}
              />
            </IconButtonRef>
          </Dialog.Trigger>
          <Dialog.Portal>
            <ModalOverlay className={styles.overlay} />
            <Dialog.Content className={clsx(styles.content)}>
              <ModalHeader closeLabel={closeLabel} title={label} hideTitle />
              <search className={styles.inner}>{form}</search>
            </Dialog.Content>
          </Dialog.Portal>
        </Dialog.Root>
      </div>
      <search className={styles.desktop}>{form}</search>
    </div>
  );
};
