import type { ProductRichLinkStoryblok } from '@string/types/generated-storyblok-types';

export function getHotspotStyle(
  inputX:
    | ProductRichLinkStoryblok
    | {
        x: number | string;
        y: number | string;
      }
    | number
    | string,
  inputY?: number | string
): React.CSSProperties {
  let x = '0';
  let y = '0';
  if (typeof inputX === 'number' || typeof inputX === 'string') {
    x = inputX ? inputX.toString() : '0';
    y = inputY ? inputY.toString() : '0';
  }
  if (typeof inputX === 'object' && 'x' in inputX && 'y' in inputX) {
    x = inputX.x ? inputX.x.toString() : '0';
    y = inputX.y ? inputX.y.toString() : '0';
  }
  return {
    insetBlockStart: `calc(${y}% - (var(--button-size-small, 1.75rem) / 2))`,
    insetInlineStart: `calc(${x}% - (var(--button-size-small, 1.75rem) / 2))`,
  };
}
