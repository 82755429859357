import { HTMLAttributes, memo, useMemo } from 'react';

import { getImageProps } from 'next/image';

import clsx from 'clsx';

import type { AssetStoryblok } from '@string/types/generated-storyblok-types';
import { getStoryblokImageSrc } from '@string/utils/get-storyblok-image-src/get-storyblok-image-src';
import { getImageLoader } from '@string/utils/image/get-loader';

import styles from './responsive-image.module.scss';

function getBreakpoint(index: number, all: ResponsiveImageRule[]) {
  const rule = all[index];
  if (!rule) {
    return null;
  }
  return rule.breakpoint
    ? rule.breakpoint
    : !rule.orientation && all.length === 2 && index > 0 && 768;
}

interface ResponsiveImageRule {
  aspectRatio: number;
  breakpoint?: number;
  orientation?: 'landscape' | 'portrait';
}

interface ResponsiveImageProps extends HTMLAttributes<HTMLPictureElement> {
  image?: AssetStoryblok;
  alt?: string;
  sizes?: string;
  rules?: ResponsiveImageRule[];
  maxInlineSize?: number;
  priority?: boolean;
}

export const ResponsiveImage: React.FC<ResponsiveImageProps> = memo(
  ({
    image,
    alt: inputAlt,
    sizes = '100vw',
    className,
    rules,
    maxInlineSize = 2880,
    priority,
    ...rest
  }) => {
    const alt = image?.alt ?? inputAlt ?? image?.title ?? image?.name ?? '';

    const common = {
      alt,
      priority,
      quality: 85,
      sizes,
    };

    const props = useMemo(() => {
      const rule = rules?.[0];
      if (!rule) {
        return null;
      }
      const width = rule.breakpoint
        ? Math.floor(rule.breakpoint * 2)
        : maxInlineSize;
      const height = Math.floor(width / rule.aspectRatio);
      const src =
        getStoryblokImageSrc(image, {
          resize: {
            height,
            width,
          },
        }) || '';
      const loader = getImageLoader(src);
      const { props } = getImageProps({
        ...common,
        height,
        loader,
        src,
        width,
      });
      return props;
    }, []);

    if (!image) {
      return null;
    }

    function ruleMapper(
      rule: ResponsiveImageRule,
      index: number,
      all: ResponsiveImageRule[]
    ) {
      const breakpoint = getBreakpoint(index, all);

      const width = breakpoint ? Math.floor(breakpoint * 2) : maxInlineSize;
      const height = Math.floor(width / rule.aspectRatio);

      const src =
        getStoryblokImageSrc(image, {
          resize: {
            height,
            width,
          },
        }) || '';

      const media = [];

      if (breakpoint) {
        media.push(`(min-width: ${breakpoint}px)`);
      }

      const nextBreakpoint = getBreakpoint(index + 1, all);

      if (nextBreakpoint) {
        media.push(`(max-width: ${nextBreakpoint - 1}px)`);
      }

      if (rule.orientation) {
        media.push(`(orientation: ${rule.orientation})`);
      }
      const loader = getImageLoader(src);

      const {
        props: { srcSet },
      } = getImageProps({
        ...common,
        height,
        loader,
        src,
        width,
      });

      if (!srcSet) {
        return null;
      }

      const sourceProps: {
        srcSet?: string;
        media?: string;
      } = { srcSet };

      if (media.length > 0) {
        sourceProps.media = ['screen', ...media].join(' and ');
      }

      return (
        <source key={`ResponsiveImage-${index}-${media}`} {...sourceProps} />
      );
    }

    if (!props) {
      return null;
    }

    const { srcSet, ...imageProps } = props;

    return (
      <picture className={clsx(styles.picture, className)} {...rest}>
        {rules?.map(ruleMapper)}
        <img
          alt={alt}
          className={styles.image}
          srcSet={srcSet}
          {...imageProps}
        />
      </picture>
    );
  }
);
