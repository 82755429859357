import type { StoryblokLanguage } from '@string/types';

export const storyblokLanguages: StoryblokLanguage[] = [
  {
    code: 'de',
    defaultMarket: 'de-de',
    localName: 'Deutsch',
    name: 'German',
  },
  {
    code: 'es',
    defaultMarket: 'es-es',
    localName: 'Español',
    name: 'Spanish',
  },
  {
    code: 'en',
    defaultMarket: 'en-gb',
    name: 'English',
  },
  {
    code: 'fr',
    defaultMarket: 'fr-fr',
    localName: 'Français',
    name: 'French',
  },
  {
    code: 'ja',
    defaultMarket: 'ja-jp',
    localName: '日本語',
    name: 'Japanese',
  },
  {
    code: 'ko',
    defaultMarket: 'ko-kr',
    localName: '한국어',
    name: 'Korean',
  },
  {
    code: 'sv',
    defaultMarket: 'sv-se',
    localName: 'Svenska',
    name: 'Swedish',
  },
  {
    code: 'zh',
    defaultMarket: 'zh-cn',
    localName: '中文',
    name: 'Chinese',
  },
] as const;
