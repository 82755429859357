'use client';

import {
  createContext,
  Dispatch,
  memo,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';

import useLocalStorage from 'react-use/lib/useLocalStorage';

import type { ByoDesign } from '@string/types/cart/byo-design';
import type { Cart } from '@string/types/cart/cart';
import type { CartCreatePayload } from '@string/types/cart/cart-create-payload';
import type { CartCurrency } from '@string/types/cart/cart-currency';
import type { LineItem } from '@string/types/cart/line-item';
import type { LineItemInput } from '@string/types/cart/line-item-input';
import type { LineItemsUpdatePayload } from '@string/types/cart/line-item-update-payload';
import type { LineItemsAddPayload } from '@string/types/cart/line-items-add-payload';
import { BundleVariantArticle } from '@string/types/portal-types/bundle-variant-articles';
import type { SiteLocale } from '@string/types/site';

import { useHeader } from '../header/use-header';

import { useCartApi } from './use-cart-api';
export interface CartTranslations {
  addToCartLabel: string;
  byoSectionLabel: string;
  cartLabel: string;
  cartUpdated: string;
  checkoutLabel: string;
  closeLabel: string;
  decreaseQuantity: string;
  emptyLabel: string;
  increaseQuantity: string;
  itemsLabel: string;
  itemsSectionLabel: string;
  openInByo: string;
  openLabel: string;
  priceLabel: string;
  productAddedToCart: string;
  removeFromCart: string;
  totalLabel: string;
  vatLabel: string;
}

export interface CartContextValue extends CartTranslations {
  loading: boolean;
  cart: Cart | null;
  byoDesigns: ByoDesign[] | null;
  cartTotal: number;
  createCart(
    input: LineItemInput | LineItemInput[] | LineItemsAddPayload
  ): Promise<void>;
  updateLineItem(input: LineItem, quantity: number): Promise<void>;
  addByoDesign(payload: LineItemsAddPayload): Promise<void>;
  deleteByoDesign(id: string): Promise<void>;
  deleteLineItem(id: string): Promise<void>;
  addLineItemToCart(item: LineItemInput): void;
  addLineItemsToCart(
    items: (LineItemInput | undefined)[] | undefined,
    title?: string
  ): void;
  addBundleToCart(
    articles: BundleVariantArticle[],
    title?: string
  ): Promise<void>;
  setLoading(loading: boolean): void;
  cartEnabled: boolean;
  cartIsEmpty: boolean;
  setCartEnabled(bol: boolean): void;
  lineItems: LineItem[];
  findStoreHref: string;
  checkoutUrl: string;
  cartTotalLabel: string;
  addedQueue: string[];
  setAddedQueue: Dispatch<SetStateAction<string[]>>;
}

export const CartContext = createContext<CartContextValue | null>(null);

export interface CartProviderProps {
  children?: React.ReactNode;
  locale: SiteLocale;
  cartApiHostname: string;
  findStorePath: string;
  channelIds: Record<string, number>;
  translations: CartTranslations;
}

export const CartProvider: React.FC<CartProviderProps> = memo(
  ({
    children,
    locale: localeInput,
    cartApiHostname,
    findStorePath,
    channelIds,
    translations: {
      addToCartLabel,
      byoSectionLabel,
      cartLabel,
      cartUpdated,
      checkoutLabel,
      closeLabel,
      decreaseQuantity,
      emptyLabel,
      increaseQuantity,
      itemsLabel,
      itemsSectionLabel,
      openInByo,
      openLabel,
      priceLabel,
      productAddedToCart,
      removeFromCart,
      totalLabel,
      vatLabel,
    },
  }) => {
    const locale = useMemo(
      () => `${localeInput.language}-${localeInput.country_code.toUpperCase()}`,
      [localeInput]
    );
    const { setHide } = useHeader();

    const [addedQueue, setAddedQueue] = useState<string[]>([]);
    const findStoreHref = useMemo(() => {
      return `/${localeInput.localeTag}${findStorePath}`;
    }, [localeInput, findStorePath]);
    const channel_id = useMemo(() => channelIds[locale], [channelIds, locale]);
    const [cartId, setCartId] = useLocalStorage('cartId', '');
    const [cartEnabled, setCartEnabled] = useState(false);
    const baseUrl = useMemo(() => {
      const url = new URL(`https://${cartApiHostname}/api/carts`);
      url.searchParams.set('locale', locale);
      url.searchParams.set(
        'include',
        'redirect_urls,line_items.physical_items.options'
      );
      return url;
    }, [locale]);

    const [cart, setCart] = useState<Cart | null>(null);
    const checkoutUrl = useMemo(
      () => cart?.redirect_urls?.checkout_url || '',
      [cart]
    );
    const [byoDesigns, setByoDesigns] = useState<ByoDesign[]>([]);
    const [loading, setLoading] = useState(true);
    const lineItems = useMemo(
      () => cart?.line_items.physical_items || [],
      [cart]
    );
    const cartIsEmpty = useMemo(
      () => !lineItems?.length && !byoDesigns?.length,
      [lineItems, byoDesigns]
    );

    const {
      addLineItemsRequest,
      createCartRequest,
      deleteByoDesignRequest,
      deleteLineItemRequest,
      fetchCartRequest,
      updateLineItemRequest,
    } = useCartApi(baseUrl, setLoading);

    const currency: CartCurrency = { code: '' };
    if (localeInput.isShopEnabled && localeInput.currency) {
      currency.code = localeInput.currency;
    }
    const cartTotal = useMemo<number>(() => {
      if (cart?.line_items.physical_items?.length) {
        return cart.line_items.physical_items.reduce((acc, item) => {
          return acc + (item.quantity ?? 1);
        }, 0);
      }
      return 0;
    }, [cart]);

    const cartTotalLabel = useMemo(() => {
      if (!cartTotal && loading) {
        return '…';
      }
      if (cartTotal) {
        return `${cartTotal}`;
      }
      return '0';
    }, [cartTotal, loading]);

    useEffect(() => {
      if (cartId && cart?.id !== cartId) {
        fetchCart();
      }
      if (!cartId) {
        setLoading(false);
      }
    }, [cartId]);

    useEffect(() => {
      if (cart?.id && cart.id !== cartId) {
        setCartId(cart.id);
      }
    }, [cart]);

    async function fetchCart() {
      if (cartId) {
        const data = await fetchCartRequest(cartId);

        if (!data) {
          setCart(null);
          setByoDesigns([]);
          setCartId('');
        }
        if (data?.cart) {
          setCart(data.cart);
        }
        if (data?.byo_designs) {
          setByoDesigns(data.byo_designs);
        }
      }
    }

    async function createCart(
      input: LineItemInput | LineItemInput[] | LineItemsAddPayload
    ) {
      const payload: CartCreatePayload = {
        byo_designs: [],
        channel_id,
        currency: currency,
        customer_id: null,
        line_items: [],
        locale,
      };
      function isLineItemsAddPayload(item: any): item is LineItemsAddPayload {
        return item.byo_designs && item.line_items;
      }

      if (isLineItemsAddPayload(input)) {
        payload.byo_designs = input.byo_designs;
        payload.line_items = input.line_items;
      }
      if (Array.isArray(input) && !isLineItemsAddPayload(input)) {
        payload.line_items = input;
      }
      if (!Array.isArray(input) && !isLineItemsAddPayload(input)) {
        payload.line_items = [input];
      }

      const data = await createCartRequest(payload);

      if (data?.cart) {
        setCart(data.cart);
      }
      if (data?.byo_designs) {
        setByoDesigns(data.byo_designs);
      }
    }

    async function updateLineItem(input: LineItem, quantity = 1) {
      if (!cart?.id) {
        return;
      }

      const payload: LineItemsUpdatePayload = {
        line_item: {
          product_id: input.product_id,
          quantity: (input.quantity ?? 1) + quantity,
          variant_id: input.variant_id,
        },
      };

      const data = await updateLineItemRequest(payload, cart.id, input.id);

      if (data?.cart) {
        setCart(data.cart);
      }
    }

    async function addByoDesign(payload: LineItemsAddPayload) {
      if (!cart?.id) {
        await createCart(payload);
      } else {
        const data = await addLineItemsRequest(payload, cart.id);
        if (data?.cart) {
          setCart(data.cart);
        }
        if (data?.byo_designs) {
          setByoDesigns(data.byo_designs);
        }
      }
    }

    async function createLineItem(input: LineItemInput | LineItemInput[]) {
      if (!cart?.id) {
        return;
      }

      const payload: LineItemsAddPayload = {
        byo_designs: [],
        line_items: [],
      };

      if (Array.isArray(input)) {
        payload.line_items = input;
      }
      if (!Array.isArray(input)) {
        payload.line_items = [input];
      }

      const data = await addLineItemsRequest(payload, cart.id);
      if (data?.cart) {
        setCart(data.cart);
      }
    }

    async function deleteLineItem(id: string) {
      if (!cart?.id) {
        return;
      } else {
        const fetchCart = cart.line_items.physical_items.length > 1;
        const data = await deleteLineItemRequest(id, cart.id, fetchCart);
        if (data?.cart) {
          setCart(data.cart);
        }
        if (!data) {
          setCart(null);
        }
      }
    }

    async function deleteByoDesign(id: string) {
      if (!cart?.id) {
        return;
      } else {
        const data = await deleteByoDesignRequest(id, cart.id);
        if (data?.cart) {
          setCart(data.cart);
        }
        if (data?.byo_designs) {
          setByoDesigns(data.byo_designs);
        }
      }
    }

    function getLineItemFromBundleArticle(
      article: BundleVariantArticle
    ): LineItemInput {
      return {
        product_id: article.big_commerce.big_commerce_product_id,
        quantity: article.amount || 1,
        variant_id: article.big_commerce.big_commerce_id, // NOT SURE
      };
    }

    async function addBundleToCart(
      articles: BundleVariantArticle[],
      title?: string
    ) {
      const lineItems: LineItemInput[] = articles.map((article) =>
        getLineItemFromBundleArticle(article)
      );

      addLineItemsToCart(lineItems, title);
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    async function addLineItemsToCart(
      lineItemsInput: (LineItemInput | undefined)[] | undefined,
      title?: string
    ) {
      // Filter out empty line items
      const lineItems = lineItemsInput?.filter(Boolean) as LineItemInput[];

      setHide(false);
      setAddedQueue((prev = []) => (title ? [...prev, title] : prev));

      // Early return if no line items
      if (!lineItems?.length) {
        return;
      }

      // Avoid hassle if there is only one line item
      if (lineItems.length === 1) {
        return addLineItemToCart(lineItems[0]);
      }

      // Create cart if there is none
      if (!cart) {
        return createCart(lineItems);
      }

      const oldItems = lineItems.filter((lineItem) =>
        cart?.line_items.physical_items.some(
          ({ variant_id }) => variant_id === lineItem.variant_id
        )
      );
      const newItems = lineItems.filter(
        (lineItem) =>
          !cart?.line_items.physical_items.some(
            ({ variant_id }) => variant_id === lineItem.variant_id
          )
      );

      let newCart: Cart | null = null;

      for (const lineItem of oldItems) {
        const oldLineItem = cart?.line_items.physical_items.find(
          ({ variant_id }) => variant_id === lineItem.variant_id
        );
        if (oldLineItem) {
          const data = await updateLineItemRequest(
            {
              line_item: {
                product_id: oldLineItem.product_id,
                quantity:
                  (oldLineItem.quantity ?? 1) + (lineItem.quantity ?? 1),
                variant_id: oldLineItem.variant_id,
              },
            },
            cart.id,
            oldLineItem.id
          );

          if (data?.cart) {
            newCart = data.cart;
          }
        }
      }

      if (newItems.length) {
        const newPayload: LineItemsAddPayload = {
          byo_designs: [],
          line_items: newItems,
        };
        const data = await addLineItemsRequest(newPayload, cart.id);
        if (data?.cart) {
          newCart = data.cart;
        }
      }
      if (newCart) {
        setCart(newCart);
      }
    }

    function addLineItemToCart({
      product_id,
      variant_id,
      quantity = 1,
    }: LineItemInput) {
      const lineItem: LineItemInput = {
        product_id,
        quantity,
        variant_id,
      };
      if (!cart) {
        return createCart(lineItem);
      }

      const oldLineItem = cart?.line_items.physical_items.find((item) => {
        return item.variant_id === variant_id;
      });

      if (!oldLineItem) {
        return createLineItem(lineItem);
      }

      const newQuantity = (oldLineItem.quantity ?? 1) + (quantity ?? 1);

      const newItem: LineItemInput = {
        product_id: oldLineItem.product_id,
        quantity: newQuantity,
        variant_id: oldLineItem.variant_id,
      };

      const payload = { line_item: newItem };

      updateLineItemRequest(payload, cart.id, oldLineItem.id).then((data) => {
        if (data?.cart) {
          setCart(data.cart);
        }
      });
    }

    const memoizedValue: CartContextValue = useMemo(() => {
      return {
        addBundleToCart,
        addByoDesign,
        addLineItemToCart,
        addLineItemsToCart,
        addToCartLabel,
        addedQueue,
        byoDesigns,
        byoSectionLabel,
        cart,
        cartEnabled,
        cartIsEmpty,
        cartLabel,
        cartTotal,
        cartTotalLabel,
        cartUpdated,
        checkoutLabel,
        checkoutUrl,
        closeLabel,
        createCart,
        decreaseQuantity,
        deleteByoDesign,
        deleteLineItem,
        emptyLabel,
        findStoreHref,
        increaseQuantity,
        itemsLabel,
        itemsSectionLabel,
        lineItems,
        loading,
        openInByo,
        openLabel,
        priceLabel,
        productAddedToCart,
        removeFromCart,
        setAddedQueue,
        setCartEnabled,
        setLoading,
        totalLabel,
        updateLineItem,
        vatLabel,
      };
    }, [
      addLineItemsToCart,
      addLineItemToCart,
      addToCartLabel,
      byoDesigns,
      byoSectionLabel,
      cart,
      cartIsEmpty,
      cartLabel,
      cartTotal,
      cartTotalLabel,
      cartUpdated,
      checkoutLabel,
      checkoutUrl,
      closeLabel,
      createCart,
      decreaseQuantity,
      deleteLineItem,
      emptyLabel,
      findStorePath,
      increaseQuantity,
      itemsLabel,
      itemsSectionLabel,
      lineItems,
      loading,
      openInByo,
      openLabel,
      priceLabel,
      productAddedToCart,
      removeFromCart,
      setLoading,
      totalLabel,
      updateLineItem,
      vatLabel,
    ]);

    return (
      <CartContext.Provider value={memoizedValue}>
        {children}
      </CartContext.Provider>
    );
  }
);
