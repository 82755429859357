'use client';
import { Root } from '@radix-ui/react-accordion';
import clsx from 'clsx';

import styles from './accordion.module.scss';

interface AccordionProps {
  children?: React.ReactNode;
  defaultValue?: string;
  className?: string;
  type?: 'single' | 'multiple';
  collapsible?: boolean;
}

export const Accordion: React.FC<AccordionProps> = ({
  children,
  defaultValue,
  className,
  type = 'single',
}) => {
  if (type === 'multiple') {
    return (
      <Root
        className={clsx(styles.root, className)}
        defaultValue={defaultValue ? [defaultValue] : []}
        type="multiple"
      >
        {children}
      </Root>
    );
  }
  return (
    <Root
      className={clsx(styles.root, className)}
      defaultValue={defaultValue}
      type={type}
      collapsible
    >
      {children}
    </Root>
  );
};
