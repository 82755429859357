import type { SiteLocale } from '@string/types';

import { getLocaleFromPathname } from './get-locale';
import { i18nConfig } from './i18n-config';
import { marketVats } from './vat';

const shopMarkets: string[] = [
  'at',
  'be',
  'be',
  'de',
  'dk',
  'fi',
  'fr',
  'lu',
  'nl',
  'nl',
  'no',
  'se',
] as const;

const currencyCodes = new Map<string, string>([
  ['at', 'EUR'],
  ['be', 'EUR'],
  ['de', 'EUR'],
  ['dk', 'DKK'],
  ['fi', 'EUR'],
  ['fr', 'EUR'],
  ['lu', 'EUR'],
  ['nl', 'EUR'],
  ['no', 'NOK'],
  ['se', 'SEK'],
]);

export const getSiteLocale = (locale?: string | null): SiteLocale => {
  const [language = 'en', country_code = 'gb'] =
    (locale?.toLowerCase() || i18nConfig.defaultLocale).split('-') || '';
  return {
    country_code,
    currency: currencyCodes.get(country_code),
    isShopEnabled: shopMarkets.includes(country_code),
    language,
    localeTag: `${language}-${country_code}`,
    vat: marketVats.get(country_code) || 0,
  };
};

export function getSiteLocaleFromPathname(pathname: string): SiteLocale {
  const locale = getLocaleFromPathname(pathname);

  return getSiteLocale(locale);
}
