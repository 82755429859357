import { Close, Description, Title } from '@radix-ui/react-dialog';
import { Root as VisuallyHidden } from '@radix-ui/react-visually-hidden';
import clsx from 'clsx';

import { default as CloseSvg } from '@string/svg/close.svg';

import { IconButtonRef } from '../icon-button/icon-button';

import styles from './modal-header.module.scss';

interface ModalHeaderProps {
  title?: string;
  description?: string;
  closeLabel: string;
  className?: string;
  titleClassName?: string;
  closeClassName?: string;
  hideTitle?: boolean;
}

interface ModalTitleProps {
  hide?: boolean;
  children?: React.ReactNode;
  className?: string;
}

const ModalTitle: React.FC<ModalTitleProps> = ({
  children,
  hide,
  className,
}) => {
  const titleComponent = (
    <Title asChild>
      <div className={clsx('text__sub-header', styles.title, className)}>
        {children}
      </div>
    </Title>
  );

  if (hide) {
    return <VisuallyHidden asChild>{titleComponent}</VisuallyHidden>;
  }

  return titleComponent;
};

export const ModalHeader: React.FC<ModalHeaderProps> = ({
  title = '',
  description,
  className,
  closeLabel,
  hideTitle,
  titleClassName,
  closeClassName,
}) => {
  return (
    <div className={clsx(styles.root, className)}>
      <ModalTitle className={titleClassName} hide={hideTitle}>
        {title}
      </ModalTitle>
      <VisuallyHidden asChild>
        <Description className={styles.description}>{description}</Description>
      </VisuallyHidden>
      <Close asChild>
        <IconButtonRef
          aria-label={closeLabel}
          className={clsx(styles.close, closeClassName)}
          title={closeLabel}
        >
          <CloseSvg
            aria-hidden={true}
            focusable={false}
            height={24}
            width={24}
          />
        </IconButtonRef>
      </Close>
    </div>
  );
};
