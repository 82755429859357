'use client';

import { memo } from 'react';

import clsx from 'clsx';
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';

import { CaretDownSvg } from '@string/svg/server';

import styles from './accordion-item-string.module.scss';

interface AccordionItemProps {
  title?: string;
  uuid?: string;
  label?: string;
  children: React.ReactNode;
  className?: string;
  smallLabel?: string;
}

function getClassName(expanded?: boolean): string {
  return clsx(styles.accordion, expanded && styles['accordion--active']);
}

export const AccordionItemString: React.FC<AccordionItemProps> = memo(
  ({ title = '', label, uuid, children, className, smallLabel }) => {
    return (
      <AccordionItem className={className} uuid={uuid}>
        <AccordionItemHeading className={styles.heading}>
          <AccordionItemState>
            {({ expanded }) => {
              return (
                <AccordionItemButton className={getClassName(expanded)}>
                  {label && (
                    <div className={styles.header__row}>
                      <span className={clsx(styles.label, 'text__list-header')}>
                        {label}
                      </span>
                      <h3 className={clsx('text__list-header', styles.title)}>
                        {title}
                      </h3>
                    </div>
                  )}
                  {!label && (
                    <div className={styles['small-title']}>
                      <span className="text__body">{title}</span>
                      {smallLabel && (
                        <span
                          className={clsx('text__body', styles['small-label'])}
                        >
                          {smallLabel}
                        </span>
                      )}
                    </div>
                  )}

                  <span className={clsx(expanded && styles.icon__expanded)}>
                    <CaretDownSvg
                      aria-hidden={true}
                      className={styles.icon}
                      focusable={false}
                      height={20}
                      width={20}
                    />
                  </span>
                </AccordionItemButton>
              );
            }}
          </AccordionItemState>
        </AccordionItemHeading>
        <AccordionItemPanel>{children}</AccordionItemPanel>
      </AccordionItem>
    );
  }
);
