type Size = [number, string];

export function getSizes(input?: Size[]): string | undefined {
  if (!input?.length) {
    return;
  }
  if (input.length == 1) {
    return input[0][1];
  }

  return input
    .sort((a, b) => a[0] - b[0])
    .map(([breakpoint, size], index, all) => {
      let media = '';
      const nextBreakpoint = all[index + 1]?.[0];

      if (index === 0 && nextBreakpoint) {
        media = `(max-width: ${nextBreakpoint}px)`;
      }
      if (index > 0 && nextBreakpoint) {
        media = `((min-width: ${breakpoint}px) and (max-width: ${nextBreakpoint}px))`;
      }
      if (index > 0 && !nextBreakpoint) {
        media = `(min-width: ${breakpoint}px)`;
      }

      return `${media} ${size}`.trim();
    })
    .reverse()
    .join(', ');
}
