import { RequestInit } from 'next/dist/server/web/spec-extension/request';

import type {
  CartCreatePayload,
  CartResponse,
  LineItemsAddPayload,
  LineItemsUpdatePayload,
} from '@string/types';

const revalidate = 0;

export const useCartApi = (
  baseUrl: URL,
  setLoading: (bol: boolean) => void
) => {
  async function createCartRequestRaw(payload: CartCreatePayload) {
    const init: RequestInit = {
      body: JSON.stringify(payload),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      mode: 'cors',
      next: { revalidate },
    };
    return fetch(baseUrl, init);
  }

  async function createCartRequest(payload: CartCreatePayload) {
    try {
      setLoading?.(true);

      const response = await createCartRequestRaw(payload);

      const data: CartResponse = await response.json();

      if (data) {
        return data;
      }
    } catch (error) {
      console.error({
        error,
        method: 'useCartApi.createCartRequest',
        url: baseUrl.toString(),
      });
    } finally {
      setLoading?.(false);
    }
  }

  async function addLineItemsRequestRaw(
    payload: LineItemsAddPayload,
    cartId: string
  ) {
    const url = new URL(baseUrl);
    url.pathname = `/api/carts/${cartId}/items`;
    const init: RequestInit = {
      body: JSON.stringify(payload),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      mode: 'cors',
    };
    return fetch(url, init);
  }
  async function addLineItemsRequest(
    payload: LineItemsAddPayload,
    cartId: string
  ) {
    try {
      setLoading(true);
      const response = await addLineItemsRequestRaw(payload, cartId);

      const data: CartResponse = await response.json();
      if (data) {
        return data;
      }
    } catch (error) {
      console.error({
        error,
        method: 'useCartApi.addLineItemsRequest',
      });
    } finally {
      setLoading(false);
    }
  }

  async function updateLineItemRequestRaw(
    payload: LineItemsUpdatePayload,
    cartId: string,
    lineItemId: string
  ) {
    const url = new URL(baseUrl);
    url.pathname = `/api/carts/${cartId}/items/${lineItemId}`;
    return fetch(url, {
      body: JSON.stringify(payload),
      headers: { 'Content-Type': 'application/json' },
      method: 'PUT',
      mode: 'cors',
      next: { revalidate },
    });
  }

  async function updateLineItemRequest(
    payload: LineItemsUpdatePayload,
    cartId: string,
    lineItemId: string
  ) {
    try {
      setLoading(true);
      const response = await updateLineItemRequestRaw(
        payload,
        cartId,
        lineItemId
      );

      const data: CartResponse = await response.json();
      if (data) {
        return data;
      }
    } catch (error) {
      console.error({
        error,
        method: 'useCartApi.updateLineItemRequest',
      });
    } finally {
      setLoading(false);
    }
  }

  async function deleteByoDesignRequest(designId: string, cartId: string) {
    const url = new URL(baseUrl);
    url.pathname = `/api/carts/${cartId}/byo_designs/${designId}`;
    try {
      setLoading(true);
      const response = await fetch(url, {
        method: 'DELETE',
        mode: 'cors',
        next: { revalidate },
      });

      const data: CartResponse = await response.json();
      if (data) {
        return data;
      }
    } catch (error) {
      console.error({
        error,
        method: 'useCartApi.deleteByoDesignRequest',
        url: url.toString(),
      });
    } finally {
      setLoading(false);
    }
  }

  async function deleteLineItemRequest(
    itemId: string,
    cartId: string,
    fetchCart?: boolean
  ) {
    const url = new URL(baseUrl);
    url.pathname = `/api/carts/${cartId}/items/${itemId}`;
    try {
      setLoading(true);
      const response = await fetch(url, {
        method: 'DELETE',
        mode: 'cors',
        next: { revalidate },
      });
      if (!response.ok) {
        throw new Error('Could NOT delete line item!');
      }

      if (response.ok && fetchCart) {
        return fetchCartRequest(cartId);
      }
    } catch (error) {
      console.error({
        error,
        method: 'useCartApi.deleteLineItemRequest',
        url: url.toString(),
      });
    } finally {
      setLoading(false);
    }
  }

  async function fetchCartRequest(cartId: string) {
    const url = new URL(baseUrl);
    url.pathname = `/api/carts/${cartId}`;
    try {
      setLoading(true);
      const response = await fetch(url, {
        headers: { 'Content-Type': 'application/json' },
        method: 'GET',
        mode: 'cors',
        next: { revalidate },
      });

      if (response.status === 404) {
        return null;
      }
      if (response.status === 200) {
        const data: CartResponse = await response.json();
        return data;
      }
    } catch (error) {
      console.error({
        error,
        method: 'useCartApi.fetchCartRequest',
        url: url.toString(),
      });
    } finally {
      setLoading(false);
    }
  }

  return {
    addLineItemsRequest,
    addLineItemsRequestRaw,
    createCartRequest,
    createCartRequestRaw,
    deleteByoDesignRequest,
    deleteLineItemRequest,
    fetchCartRequest,
    updateLineItemRequest,
    updateLineItemRequestRaw,
  };
};
