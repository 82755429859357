import { forwardRef } from 'react';

import { Overlay } from '@radix-ui/react-dialog';
import clsx from 'clsx';

import styles from './modal-overlay.module.scss';

interface ModalOverlayProps {
  className?: string;
  style?: React.CSSProperties;
}

export const ModalOverlay: React.FC<ModalOverlayProps> = forwardRef<
  HTMLDivElement,
  ModalOverlayProps
>(({ className, style }, ref) => {
  return (
    <Overlay
      ref={ref}
      className={clsx(styles.overlay, className)}
      style={style}
    />
  );
});
