'use client';
import { memo, useEffect, useMemo, useState } from 'react';

import { useRouter, useSearchParams } from 'next/navigation';

import { getIcatMedia } from '@string/actions/get-icat-media';
import { Filter } from '@string/components/filter/filter';
import { SecondaryHeroFiles } from '@string/components/secondary-hero-files/secondary-hero-files';
import { Spinner } from '@string/components/spinner/spinner';
import type { Datasources } from '@string/types/datasource';
import type { MediaItem } from '@string/types/icat-types/media';
import type { IcatParams } from '@string/types/icat-types/params';

import { List } from './list';
import styles from './media-list-client.module.scss';

interface MediaListClientProps {
  datasources?: Datasources;
  basePath: string;
  searchLabel?: string;
  title?: string;
  closeLabel?: string;
  filters?: string[];
}

export const MediaListClient: React.FC<MediaListClientProps> = memo(
  ({ datasources, basePath, searchLabel, title, filters = [], closeLabel }) => {
    const searchParams = useSearchParams();
    const router = useRouter();

    const [media, setMedia] = useState<MediaItem[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const mediaLength = useMemo(
      () => (!loading ? media?.length : undefined),
      [loading, media]
    );
    const [searchInput, setSearchInput] = useState('');

    const handleFetch = async (params: IcatParams) => {
      setLoading(true);
      const mediaData = await getIcatMedia(params);
      setMedia(mediaData ?? []);
      setLoading(false);
    };

    useEffect(() => {
      const params = {
        album: searchParams?.get('album'),
        search: searchParams?.get('search'),
      };
      handleFetch(params);
    }, [searchParams]);

    const updateSearchParams = (params: Record<string, string>) => {
      const currentParams = new URLSearchParams(searchParams.toString());
      Object.keys(params).forEach((key) => {
        if (params[key]) {
          currentParams.set(key, params[key]);
        } else {
          currentParams.delete(key);
        }
      });
      router.push(`?${currentParams.toString()}`);
    };
    function filterMapper(filter: string | undefined, i: number) {
      if (!filter) {
        return null;
      }
      const [id, name] = filter.split('_');
      const label = datasources?.productFilters.get(name);
      if (!label) {
        return null;
      }
      const path = name.includes('all')
        ? basePath
        : `${basePath}?${new URLSearchParams({ album: id }).toString()}`;

      const album = searchParams?.get('album');
      const isActive = album === id || (!album && name.includes('all'));
      return (
        <Filter
          key={`Filter-${filter}-${i}`}
          id={`Filter-${filter}`}
          isActive={isActive}
          label={label}
          path={path}
        />
      );
    }

    const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
      e.preventDefault();
      const formData = new FormData(e.currentTarget);
      const search = formData.get('search')?.toString() || '';
      updateSearchParams({ search });
    };

    const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      setSearchInput(e.target.value);
    };

    return (
      <div>
        <SecondaryHeroFiles
          closeLabel={closeLabel}
          count={mediaLength}
          label={searchLabel}
          onChange={onChange}
          onSubmit={onSubmit}
          submitLabel={searchLabel}
          title={title}
          value={searchInput}
        >
          {filters.map(filterMapper)}
        </SecondaryHeroFiles>
        {loading && (
          <div className={styles.spinner}>
            <Spinner />
          </div>
        )}
        {!loading && (
          <List
            downloadLabel={datasources?.microcopy.get('download')}
            filenameLabel={datasources?.microcopy.get('filename')}
            items={media}
            // productsLabel={datasources?.microcopy.get('products')}
            specificationsLabel={datasources?.microcopy.get('specifications')}
          />
        )}
      </div>
    );
  }
);
