'use client';

import React, {
  memo,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from 'react';

import Link from 'next/link';

import clsx from 'clsx';

import { Hotspot } from '@string/components/hotspot/hotspot';
import { ResponsiveImage } from '@string/components/responsive-image/responsive-image';
import { useTheme } from '@string/hooks/use-theme/use-theme';
import { getHotspotStyle } from '@string/storyblok/get-hotspot-style/get-hotspot-style';
import type {
  Datasources,
  ProductDetailFields,
  SiteLocale,
} from '@string/types';
import type {
  HotspotImageStoryblok,
  ProductRichLinkStoryblok,
} from '@string/types/generated-storyblok-types';

import styles from './hotspot-hero-slideshow.module.scss';

interface HotspotHeroSlideshowProps {
  locale: SiteLocale;
  items?: HotspotImageStoryblok[];
  className?: string;
  datasources?: Datasources;
  pdp: ProductDetailFields;
}

const aspectRatio = 13 / 24;
const aspectRatioDesktop = 21 / 9;

export const HotspotHeroSlideshow: React.FC<HotspotHeroSlideshowProps> = memo(
  ({ locale, className, items = [], datasources, pdp }) => {
    const id = useId();
    const titleRef = useRef<HTMLParagraphElement>(null);
    const innerRef = useRef<HTMLDivElement>(null);
    const listRef = useRef<HTMLDivElement>(null);
    const [currentSlide, setCurrentSlide] = useState<number>(0);
    const [paused, setPaused] = useState<boolean>(false);
    const [over, setOver] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);

    const slide = useMemo(() => items[currentSlide], [items, currentSlide]);
    const title = useMemo(() => {
      const parts = (slide.title ?? '').trim().replace(/\s+/, ' ').split(' ');
      const partsMapper = (part: string, index: number) => {
        const style = {
          '--animation-delay': `${0.1 + index * 0.15}s`,
        } as React.CSSProperties;
        return (
          <span key={`HotspotHeroSlideshow-${id}-title-${index}`} style={style}>
            {part}
          </span>
        );
      };
      return <>{parts.map(partsMapper)}</>;
    }, [slide, id]);
    const { themeClassName, themeStyle } = useTheme(slide.background);

    const hotspots = useMemo(
      () => items[currentSlide]?.hotspots || [],
      [items, currentSlide]
    );

    useEffect(() => {
      if (open || over) {
        setPaused(true);
      }
      if (!open && !over) {
        setPaused(false);
      }
    }, [open, over]);

    function hotspotMapper(
      item: ProductRichLinkStoryblok | undefined,
      i: number
    ) {
      if (!item) {
        return null;
      }
      const style = {
        ...getHotspotStyle(item),
        '--animation-delay': `${i * 0.15}s`,
      };
      const onMouseEnter = () => {
        setOver(true);
      };
      const onMouseLeave = () => {
        setOver(false);
      };
      const onOpenChange = (value: boolean) => {
        setOpen(value);
      };

      return (
        <Hotspot
          key={`hotspot-${id}-${item?._uid}-${i}`}
          className={clsx(styles.hotspot)}
          datasources={datasources}
          image={items[currentSlide]?.image}
          locale={locale}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onOpenChange={onOpenChange}
          path={item.path}
          pdp={pdp}
          style={style}
          variant="light"
        />
      );
    }

    function linkMapper(
      item: HotspotImageStoryblok | undefined,
      index: number
    ) {
      if (!item) {
        return null;
      }
      const key = `HotspotHeroSlideshow-${id}-link-${item._uid}-${index}`;
      const href = item.link?.url || '';
      const label = item.teaser_title ?? item.title ?? item.l;
      const onAnimationEnd = () => {
        setCurrentSlide((prev) => (prev + 1) % items.length);
      };
      const style = { '--transition-duration': '5s' } as React.CSSProperties;
      return (
        <Link
          key={key}
          className={clsx(
            'text__small',
            styles.link,
            index === currentSlide && styles['link--active']
          )}
          data-link-index={index}
          href={href}
          onAnimationEnd={onAnimationEnd}
          style={style}
        >
          {label}
        </Link>
      );
    }

    function imageMapper(
      item: HotspotImageStoryblok | undefined,
      index: number
    ) {
      if (!item) {
        return null;
      }
      const key = `HotspotHeroSlideshow-${id}-image-${item._uid}`;

      return (
        <ResponsiveImage
          key={key}
          alt={item.title}
          className={clsx(
            styles.image,
            index === currentSlide && styles['image--active']
          )}
          data-image-index={index}
          image={item.image}
          rules={[{ aspectRatio }, { aspectRatio: aspectRatioDesktop }]}
          priority
        />
      );
    }

    useEffect(() => {
      // Animate title
      if (titleRef.current) {
        const animations = titleRef.current.getAnimations({ subtree: true });

        for (const animation of animations) {
          animation.cancel();
          animation.play();
        }
      }
      // Animate image
      if (innerRef.current) {
        const animations =
          innerRef.current
            .querySelector(`[data-image-index="${currentSlide}"]`)
            ?.getAnimations() || [];
        for (const animation of animations) {
          animation.cancel();
          animation.play();
        }
      }
    }, [currentSlide]);

    useEffect(() => {
      if (listRef.current) {
        const animations = listRef.current?.getAnimations({ subtree: true });
        for (const animation of animations) {
          if (paused) {
            animation.pause();
          }
          if (!paused) {
            animation.play();
          }
        }
      }
    }, [paused]);

    return (
      <section
        className={clsx(className, themeClassName, styles.root)}
        style={themeStyle}
      >
        <div ref={innerRef} className={clsx(styles.inner)}>
          {hotspots.map(hotspotMapper)}
          <div className={clsx('container', styles.content)}>
            <div ref={listRef} className={clsx(styles.list)}>
              {items.map(linkMapper)}
            </div>
            <p
              ref={titleRef}
              className={clsx('text__hero-header-fluid', styles.title)}
            >
              {title}
            </p>
          </div>
          {items.map(imageMapper)}
        </div>
      </section>
    );
  }
);
