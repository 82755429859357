'use client';
import { memo } from 'react';

import { useRouter } from 'next/navigation';

import clsx from 'clsx';

import { ButtonLarge } from '@string/components/button-large/button-large';
import { defaultPerPage } from '@string/portal-api/handle-search-params';

import styles from './pagination.module.scss';

interface PaginationProps {
  page: number;
  loadMoreLabel: string;
  perPage: number;
  className?: string;
}

export const Pagination: React.FC<PaginationProps> = memo(
  ({ loadMoreLabel, perPage, className }) => {
    const router = useRouter();

    const onClick = () => {
      const params = { per_page: (perPage + defaultPerPage).toString() };
      const newSearchParams = new URLSearchParams(params);
      router.push(`?${newSearchParams.toString()}`, { scroll: false });
    };
    return (
      <div className={clsx(styles.root, className)}>
        <ButtonLarge
          aria-label={loadMoreLabel}
          onClick={onClick}
          variant="dark"
          wide
        >
          <p>{loadMoreLabel}</p>
        </ButtonLarge>
      </div>
    );
  }
);
