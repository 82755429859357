import React, { forwardRef } from 'react';

import { Content, Header, Item, Trigger } from '@radix-ui/react-accordion';
import clsx from 'clsx';

import { default as CaretDownSvg } from '@string/svg/caret-down.svg';

import styles from './accordion-item.module.scss';

interface AccordionItemProps {
  children?: React.ReactNode;
  className?: string;
  value: string;
  trigger?: React.ReactNode;
  triggerClassName?: string;
  outerClassName?: string;
}

export const AccordionItem: React.FC<AccordionItemProps> = ({
  children,
  value,
  className,
  trigger,
  triggerClassName,
  outerClassName,
}) => {
  return (
    <Item className={clsx(styles.root, outerClassName)} value={value}>
      <AccordionTrigger className={triggerClassName}>
        {trigger}
      </AccordionTrigger>
      <AccordionContent className={className}>{children}</AccordionContent>
    </Item>
  );
};

type AccordionContentProps = React.HTMLAttributes<HTMLDivElement>;

const AccordionContent = forwardRef<HTMLDivElement, AccordionContentProps>(
  ({ children, className, ...props }, forwardedRef) => (
    <Content className={clsx(styles.content)} {...props} ref={forwardedRef}>
      <div className={clsx(styles.inner, className)}>{children}</div>
    </Content>
  )
);

interface AccordionTriggerProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  headerClassName?: string;
}

const AccordionTrigger = forwardRef<HTMLButtonElement, AccordionTriggerProps>(
  ({ children, className, headerClassName, ...props }, forwardedRef) => (
    <Header asChild>
      <div className={clsx(styles.header, headerClassName)}>
        <Trigger
          className={clsx('text__navigation', styles.trigger, className)}
          {...props}
          ref={forwardedRef}
        >
          {children}
          <CaretDownSvg
            className={styles.chevron}
            focusable={false}
            height={20}
            width={20}
            aria-hidden
          />
        </Trigger>
      </div>
    </Header>
  )
);
