'use client';
import { usePathname } from 'next/navigation';

import { SiteLocale } from '@string/types';
import { getSiteLocaleFromPathname } from '@string/utils/locale/get-site-locale';

export function useLocale(): SiteLocale {
  const pathname = usePathname();
  return getSiteLocaleFromPathname(pathname);
}
