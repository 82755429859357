'use client';
import { memo, useId } from 'react';

import * as Accordion from '@radix-ui/react-accordion';
import clsx from 'clsx';
import { render } from 'storyblok-rich-text-react-renderer';

import { BaseCarousel } from '@string/components/base-carousel/base-carousel';
import { StringImage } from '@string/components/string-image/string-image';
import { getSizes } from '@string/utils/image/get-sizes';

import { ExplanationItem, ExplanationItemChild } from '../product-explanation';

import styles from './list-content.module.scss';

interface ListContentProps {
  items: ExplanationItem[];
}

export const ListContent: React.FC<ListContentProps> = memo(({ items }) => {
  const uid = useId();

  function childMapper(article: ExplanationItemChild) {
    const alt =
      article?.image?.alt ||
      article?.image?.title ||
      article?.title ||
      'Article image';
    const src = article?.image?.filename;
    const sizes = getSizes([
      [0, '25vw'],
      [1920, '455px'],
    ]);
    return (
      <article
        key={`ListContent-${uid}-Article-${article._uid}`}
        className={clsx('text__body', styles.article)}
      >
        {src && (
          <figure className={styles.figure}>
            <StringImage
              alt={alt}
              className={styles.image}
              sizes={sizes}
              src={src}
              fill
            />
          </figure>
        )}
        <p className={clsx('text__body', styles.title)}>{article.title}</p>
        {render(article.description)}
      </article>
    );
  }

  const itemMapper = (item: ExplanationItem, index: number) => {
    const key = `ListContent-${uid}-item-${item._uid}`;
    const value = `value-${index + 1}`;
    return (
      <Accordion.Item key={key} className={styles.item} value={value}>
        <Accordion.Header className={clsx(styles.header)}>
          <Accordion.Trigger
            className={clsx('text__component-header', styles.trigger)}
          >
            {item.title}
          </Accordion.Trigger>
        </Accordion.Header>
        <Accordion.Content className={clsx(styles.content)}>
          <div className={clsx(styles.inner)}>
            <div className={clsx('text__body', styles.description)}>
              {render(item.description)}
            </div>
            <BaseCarousel
              className={clsx(styles.carousel)}
              headClassName={styles['carousel-head']}
              listClassName={styles.list}
              theme="light"
            >
              {item.items?.map(childMapper)}
            </BaseCarousel>
          </div>
        </Accordion.Content>
      </Accordion.Item>
    );
  };
  return (
    <Accordion.Root
      className={styles.root}
      defaultValue="value-1"
      type="single"
    >
      {items?.map(itemMapper)}
    </Accordion.Root>
  );
});
