import { type I18NConfig } from 'next/dist/server/config-shared';

import type { MarketData } from '@string/types';

import { markets } from './market-map';
import { storyblokLanguages } from './storyblok-lang-map';

function mapLangAndLocales() {
  // TODO pre build/dev script, is working in another file pre-fetch-locales.ts
  // Just a bit gamble to implement now because of build and reading env. variables
  const locales = markets?.map((market: MarketData) => {
    const marketLocales: string[] = [];
    market.languages.forEach((mLanguage) => {
      const language = storyblokLanguages.find(
        (storyblokLang) => storyblokLang.code === mLanguage
      );
      if (language) {
        marketLocales.push(`${language.code}-${market.code}`);
      }
    });
    return marketLocales;
  });

  return [...new Set(locales.flat())].sort();
}

const locales = mapLangAndLocales();

export const i18nConfig: I18NConfig = {
  defaultLocale: 'en-gb',
  locales,
};
