import clsx from 'clsx';

import styles from './badge.module.scss';

interface BadgeProps {
  text: string;
  color?: 'blue';
}

export const Badge: React.FC<BadgeProps> = ({ text, color = 'blue' }) => {
  return <span className={clsx(styles['root'], styles[color])}>{text}</span>;
};
