'use client';
import { memo, useId } from 'react';

import clsx from 'clsx';

import { Accordion } from '@string/components/accordion/accordion';
import { AccordionItem } from '@string/components/accordion-item/accordion-item';
import { ButtonLargeLink } from '@string/components/button-large/button-large';
import { StringImage } from '@string/components/string-image/string-image';
import type { RichLinkStoryblok } from '@string/types/generated-storyblok-types';
import type { Color } from '@string/types/portal-types/color';
import { getSizes } from '@string/utils/image/get-sizes';
import { isDarkTheme } from '@string/utils/theme/is-dark-theme';

import styles from './color-list-content.module.scss';

export type ListItem = RichLinkStoryblok & { colors: number[] };

interface ColorListContentProps {
  items: ListItem[];
  item_label?: string;
  globalColors: Color[];
}

export const ColorListContent: React.FC<ColorListContentProps> = memo(
  ({ items, item_label, globalColors }) => {
    const uid = useId();
    function miniColorMapper(color: number): React.ReactNode {
      const colorObj = globalColors.find((c) => c.id === color);
      if (!colorObj) {
        return null;
      }
      const style: React.CSSProperties = {};
      if (colorObj.image) {
        style.backgroundImage = `url(${colorObj.image})`;
      }

      const isDark = colorObj?.hex && isDarkTheme('#' + colorObj.hex);

      return (
        <li
          key={`ColorListContent-${uid}-MiniColor-${color}`}
          className={clsx(styles['mini-colors-item'], !isDark && styles.light)}
          style={style}
        />
      );
    }

    const itemMapper = (item: ListItem, i: number): React.ReactNode => {
      const key = `ColorListContent-${uid}-item-${item._uid}`;
      const value = `item-${item._uid}`;

      function colorMapper(colorId: number): React.ReactNode {
        const color = globalColors.find(({ id }) => id === colorId);
        if (!color) {
          return null;
        }

        const backgroundImage = `url(${color.image})`;
        const backgroundColor = `#${color?.hex}`;
        const isDark = isDarkTheme(backgroundColor);
        const textColor = isDark ? '#fff' : '#101010';
        const borderColor = isDark ? 'transparent' : '#D7D7D7';

        return (
          <div
            key={`ColorListContent-${uid}-item-${item._uid}-color-${colorId}`}
            className={styles.color}
            style={{
              backgroundColor,
              backgroundImage,
              borderColor,
              color: textColor,
            }}
          >
            <span className="text__body">{color.name}</span>
          </div>
        );
      }

      const alt =
        item.image?.alt ?? item.image?.title ?? item.title ?? item.label ?? '';
      const imageSrc = item.image?.filename;
      const href = item.link?.url || '';

      const colors = [...item.colors].reverse();
      const trigger = (
        <div className={styles.trigger}>
          <span className={clsx('text__component-header', styles.label)}>
            {item.label}
          </span>
          <span className={styles.extra}>
            <ul className={styles['mini-colors']}>
              {colors.map(miniColorMapper)}
              <li className={clsx('text__body', styles['mini-colors-label'])}>
                {item_label}
              </li>
            </ul>
          </span>
        </div>
      );
      const sizes = getSizes([
        [0, '100vw'],
        [768, '50vw'],
        [1920, '930px'],
      ]);
      return (
        <AccordionItem
          key={key}
          className={styles.item}
          trigger={trigger}
          triggerClassName={styles['trigger-container']}
          value={value}
        >
          <div className={styles.panel}>
            {colors.map(colorMapper)}
            <figure className={styles.image}>
              {imageSrc && (
                <StringImage alt={alt} sizes={sizes} src={imageSrc} fill />
              )}
            </figure>
          </div>
          <div className={styles.content}>
            <div className={styles.inner}>
              <h3 className={clsx('text__sub-header', styles.title)}>
                {item.title}
              </h3>
              <p className={clsx('text__body', styles.description)}>
                {item.description}
              </p>
              {href && (
                <ButtonLargeLink
                  className={styles.link}
                  href={href}
                  variant="dark"
                >
                  {item.link_text}
                </ButtonLargeLink>
              )}
            </div>
          </div>
        </AccordionItem>
      );
    };
    return (
      <div>
        <Accordion>{items.map(itemMapper)}</Accordion>
      </div>
    );
  }
);
