'use client';

import { memo } from 'react';

import Link from 'next/link';
import { usePathname } from 'next/navigation';

import clsx from 'clsx';

import { inGroupNavigation } from '@string/utils/a11y/in-group-navigation';

import styles from './filter.module.scss';

interface FilterProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  label: string;
  path: string;
  isActive?: boolean;
}

export const Filter: React.FC<FilterProps> = memo(
  ({ label, path = '', isActive: mIsActive, ...props }) => {
    const pathname = usePathname();
    const isActive =
      pathname.toLowerCase().includes(label.toLowerCase()) || mIsActive;

    return (
      <Link
        className={clsx(
          'text__navigation',
          isActive && 'text__navigation--active',
          styles.root,
          isActive && styles.active
        )}
        href={path}
        {...props}
        onKeyDown={inGroupNavigation}
        type="button"
      >
        {label}
      </Link>
    );
  }
);
