import clsx from 'clsx';

import { getSizes } from '@string/utils/image/get-sizes';

import { StringImage } from '../string-image/string-image';

import styles from './product-detail-image.module.scss';

interface ProductDetailImageProps {
  src?: string | null;
  alt?: string;
  loading?: boolean;
  sizes?: string;
  priority?: boolean;
}

const defaultSizes = getSizes([
  [0, '100vw'],
  [768, '50vw'],
  [1920, '864px'],
]);

export const ProductDetailImage: React.FC<ProductDetailImageProps> = ({
  src,
  alt = '',
  loading,
  sizes = defaultSizes,
  priority,
}) => {
  return (
    <figure className={clsx(styles.figure, loading && styles.loading)}>
      {src && !loading && (
        <StringImage
          alt={alt}
          className={styles.image}
          priority={priority}
          sizes={sizes}
          src={src || ''}
          fill
        />
      )}
    </figure>
  );
};
