import clsx from 'clsx';

import {
  SecondaryHero,
  SecondaryHeroProps,
} from '../secondary-hero/secondary-hero';
import { SecondaryHeroFilters } from '../secondary-hero-filters/secondary-hero-filters';
import {
  SecondaryHeroSearch,
  type SecondaryHeroSearchProps,
} from '../secondary-hero-search/secondary-hero-search';

import styles from './secondary-hero-files.module.scss';

interface SecondaryHeroFilesProps
  extends Pick<SecondaryHeroProps, 'count' | 'title'>,
    Pick<
      SecondaryHeroSearchProps,
      'closeLabel' | 'label' | 'onChange' | 'onSubmit' | 'submitLabel' | 'value'
    > {
  children?: React.ReactNode;
  className?: string;
}
export const SecondaryHeroFiles: React.FC<SecondaryHeroFilesProps> = ({
  title,
  count,
  children,
  closeLabel,
  label,
  onChange,
  onSubmit,
  submitLabel,
  value,
  className,
}) => {
  return (
    <SecondaryHero
      className={clsx(styles.hero, className)}
      count={count}
      title={title}
      noBorder
    >
      <SecondaryHeroFilters className={styles.filters}>
        {children}
      </SecondaryHeroFilters>
      <SecondaryHeroSearch
        className={styles.search}
        closeLabel={closeLabel}
        label={label}
        onChange={onChange}
        onSubmit={onSubmit}
        submitLabel={submitLabel}
        value={value}
      />
    </SecondaryHero>
  );
};
