'use client';

import { useId } from 'react';

import * as Dialog from '@radix-ui/react-dialog';
import clsx from 'clsx';

import { useModal } from '@string/context/modal/use-modal';
import { default as ExternalSvg } from '@string/svg/external.svg';
import { OverlayDetails } from '@string/types/overlay-details';
import { getSizes } from '@string/utils/image/get-sizes';

import {
  DocumentModalTrigger,
  type DocumentModalTriggerProps,
} from '../document-modal-trigger/document-modal-trigger';
import { ModalHeader } from '../modal-header/modal-header';
import { ModalOverlay } from '../modal-overlay/modal-overlay';
import { StringImage } from '../string-image/string-image';

import styles from './document-modal-card.module.scss';

interface DocumentModalCardProps
  extends Omit<DocumentModalTriggerProps, 'className'> {
  className?: string;
  figureClassName?: string;
  triggerFigureClassName?: string;
  overlay: OverlayDetails;
  style?: React.CSSProperties;
  closeLabel?: string;
  children?: React.ReactNode;
  downloadLabel?: string;
}

export const DocumentModalCard: React.FC<DocumentModalCardProps> = ({
  figureClassName,
  triggerFigureClassName,
  closeLabel = 'Close',
  title,
  description,
  src,
  alt,
  overlay,
  children,
  downloadLabel = 'Download',
}) => {
  const uid = useId();
  const { open, setOpen } = useModal();

  const onOpenChange = (_open: boolean) => {
    setOpen(undefined);
  };

  const linkMapper = (href: string, i: number, all: string[]) => {
    const suffix = all.length > 1 ? ` ${i + 1}/${all.length}` : '';
    const key = `DocumentListOverlay-${uid}-${href}`;
    const url = new URL(href);
    const external = !url.pathname.startsWith('/assets/');
    // This needs to use a tag and not next/link for download to work
    return (
      <a
        key={key}
        className={clsx('text__body', 'text__link', styles.link)}
        download={!external}
        href={href}
        rel={external ? 'noreferrer' : undefined}
        target={external ? '_blank' : undefined}
      >
        <span>
          {downloadLabel}
          {suffix}
        </span>
        {external && (
          <ExternalSvg
            aria-hidden={true}
            focusable={false}
            height={20}
            width={20}
          />
        )}
      </a>
    );
  };

  if (!overlay) {
    return null;
  }

  const sizes = getSizes([
    [0, '100vw'],
    [768, '800px'],
  ]);

  return (
    <Dialog.Root onOpenChange={onOpenChange} open={open}>
      <DocumentModalTrigger
        alt={alt}
        description={description}
        figureClassName={triggerFigureClassName}
        src={src}
        title={title}
      />
      <Dialog.Portal>
        <ModalOverlay className={styles.overlay} />
        <Dialog.Content
          className={clsx(
            styles.content,
            overlay?.orientation && styles[overlay.orientation]
          )}
        >
          <ModalHeader closeLabel={closeLabel} title={title} hideTitle />
          <div className={styles.inner}>
            <figure
              className={clsx(
                styles.figure,
                overlay?.orientation && styles[overlay.orientation],
                figureClassName
              )}
            >
              <StringImage
                alt={overlay.alt}
                className={styles.image}
                sizes={sizes}
                src={overlay.src}
                fill
              />
            </figure>
            <div className={styles.details}>
              <div className={styles.header}>
                <p className={styles.title}>{overlay.title}</p>
                <div className={styles.list}>
                  {overlay.hrefs?.map(linkMapper)}
                </div>
              </div>
              {children}
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
