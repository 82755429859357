import { AnchorHTMLAttributes, ButtonHTMLAttributes } from 'react';

import Link from 'next/link';

import clsx from 'clsx';

import type { ThemeVariant } from '@string/types';

import styles from './button-large.module.scss';

interface ButtonLargeSharedProps {
  variant?: ThemeVariant | 'filled';
  wide?: boolean;
}

function getClassName(
  variant?: ThemeVariant | 'filled',
  wide?: boolean,
  disabled?: boolean,
  className?: string
): string {
  return clsx(
    'text__small',
    styles.root,
    variant === 'filled' && styles.filled,
    variant === 'dark' && styles.dark,
    variant === 'light' && styles.light,
    disabled && styles.disabled,
    wide && styles.wide,
    className
  );
}

export type ButtonLargeProps = ButtonHTMLAttributes<HTMLButtonElement> &
  ButtonLargeSharedProps;

export const ButtonLarge: React.FC<ButtonLargeProps> = ({
  children,
  className,
  variant = 'filled',
  wide,
  ...props
}) => {
  return (
    <button
      className={getClassName(variant, wide, false, className)}
      data-disabled={props.disabled}
      {...props}
    >
      {children}
    </button>
  );
};

export interface ButtonLargeLinkProps
  extends AnchorHTMLAttributes<HTMLAnchorElement>,
    ButtonLargeSharedProps {
  disabled?: boolean;
}

export const ButtonLargeLink: React.FC<ButtonLargeLinkProps> = ({
  children,
  className,
  variant = 'filled',
  wide,
  href = '',
  disabled,
  ...props
}) => {
  return (
    <Link
      className={getClassName(variant, wide, disabled, className)}
      data-disabled={disabled}
      href={href}
      {...props}
    >
      {children}
    </Link>
  );
};
