import clsx from 'clsx';

import styles from './iframe.module.scss';
interface IframeProps {
  title: string;
  src: string;
  className?: string;
  containerClassName?: string;
  style?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
}

export const Iframe: React.FC<IframeProps> = ({
  title,
  src,
  className,
  containerClassName,
  style,
  containerStyle,
}) => {
  const sandbox = [
    'allow-downloads',
    'allow-forms',
    'allow-modals',
    // 'allow-orientation-lock',
    // 'allow-pointer-lock',
    'allow-popups',
    'allow-popups-to-escape-sandbox',
    // 'allow-presentation',
    'allow-same-origin',
    'allow-scripts',
    'allow-storage-access-by-user-activation',
    'allow-top-navigation',
    'allow-top-navigation-by-user-activation',
    'allow-top-navigation-to-custom-protocols',
  ].join(' ');
  return (
    <div
      className={clsx(styles.root, containerClassName)}
      style={containerStyle}
    >
      <iframe
        className={clsx(styles.iframe, className)}
        loading="lazy"
        sandbox={sandbox}
        src={src}
        style={style}
        title={title}
      />
    </div>
  );
};
