import clsx from 'clsx';

import styles from './spinner.module.scss';

interface SpinnerProps {
  className?: string;
  style?: React.CSSProperties;
}

export const Spinner: React.FC<SpinnerProps> = ({ className, style }) => {
  return <div className={clsx(styles.spinner, className)} style={style} />;
};
