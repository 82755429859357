'use client';

import { createContext, memo, useEffect, useMemo, useState } from 'react';

import useDetectScroll, { Axis } from '@smakss/react-scroll-direction';
export interface HeaderContextValue {
  hide: boolean;
  top: boolean;
  setHide(hide: boolean): void;
}

export const HeaderContext = createContext<HeaderContextValue | null>(null);

interface HeaderProviderProps {
  children?: React.ReactNode;
}

export const HeaderProvider: React.FC<HeaderProviderProps> = memo(
  ({ children }) => {
    const { scrollDir, scrollPosition } = useDetectScroll({ axis: Axis.Y });
    const [hide, setHide] = useState<boolean>(false);
    const [top, setTop] = useState<boolean>(true);
    const value = useMemo(() => {
      return {
        hide,
        setHide,
        top,
      };
    }, [hide, top]);

    useEffect(() => {
      if (scrollDir === 'up') {
        setHide(false);
      }
      if (scrollDir === 'down' && scrollPosition.top > 72) {
        setHide(true);
      }
      if (scrollPosition.top <= 1) {
        setTop(true);
      }
      if (scrollPosition.top > 1) {
        setTop(false);
      }
    }, [scrollDir, scrollPosition]);

    return (
      <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>
    );
  }
);
