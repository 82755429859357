import { type ImageLoader } from 'next/dist/client/image-component';
import { type StaticImport } from 'next/dist/shared/lib/get-img-props';

import { srcIsStoryblok, storyblokLoader } from './storyblok-loader';

export function getImageLoader(
  src?: string | StaticImport
): undefined | ImageLoader {
  if (typeof src !== 'string' || !src) {
    return undefined;
  }
  let loader = undefined;

  try {
    if (srcIsStoryblok(src)) {
      loader = storyblokLoader;
    }

    return loader;
  } catch (error) {
    console.error('Error parsing URL', error);
    return undefined;
  }
}
