import clsx from 'clsx';

import styles from './product-detail-price.module.scss';

interface ProductDetailPriceProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  loading?: boolean;
}

export const ProductDetailPrice: React.FC<ProductDetailPriceProps> = ({
  children,
  className,
  style,
  loading,
}) => {
  return (
    <div
      className={clsx(
        'text__body',
        styles.price,
        loading && styles.loading,
        className
      )}
      style={style}
    >
      {children}
    </div>
  );
};
