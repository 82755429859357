const revalidate = 3600;

export const useDownload = () => {
  function downloadFiles(hrefs: string[]) {
    hrefs.forEach((href) => {
      const proxyUrl = `/api/download?url=${encodeURIComponent(href)}`;

      fetch(proxyUrl, { next: { revalidate } })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.blob();
        })
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          const temporaryDownloadLink = document.createElement('a');
          temporaryDownloadLink.style.display = 'none';
          temporaryDownloadLink.href = url;

          const filename = href.split('/').pop();
          temporaryDownloadLink.setAttribute(
            'download',
            filename ?? 'document'
          );

          document.body.appendChild(temporaryDownloadLink);
          temporaryDownloadLink.click();

          document.body.removeChild(temporaryDownloadLink);
          URL.revokeObjectURL(url);
        })
        .catch((error) => console.error('Error downloading the file:', error));
    });
  }

  return { downloadFiles };
};
