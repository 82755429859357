import { type StaticImport } from 'next/dist/shared/lib/get-img-props';

import { getStoryblokSrcPath, srcIsStoryblok } from './storyblok-loader';

export function srcIsSvg(src: string | StaticImport) {
  if (typeof src !== 'string') {
    return false;
  }

  const url = new URL(src);

  if (srcIsStoryblok(src)) {
    const path = getStoryblokSrcPath(src);
    return path.endsWith('.svg');
  }
  return url.pathname.endsWith('.svg');
}
