import { forwardRef } from 'react';

import clsx from 'clsx';

import styles from './icon-button.module.scss';

type IconButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

export const IconButton: React.FC<IconButtonProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <button className={clsx(styles.button, className)} {...props}>
      {children}
    </button>
  );
};

export const IconButtonRef = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ children, className, ...props }, ref) => (
    <button ref={ref} className={clsx(styles.button, className)} {...props}>
      {children}
    </button>
  )
);
