import React from 'react';

import styles from './product-list-header.module.scss';

type ProductListHeaderProps = { children?: React.ReactNode };

export const ProductListHeader: React.FC<ProductListHeaderProps> = ({
  children,
}) => {
  return (
    <div className={styles.header}>
      <div className={styles.filter__container}>{children}</div>
    </div>
  );
};
