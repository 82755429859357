'use client';
import { memo, useEffect, useId, useMemo, useState } from 'react';

import Link from 'next/link';

import clsx from 'clsx';
import { Accordion } from 'react-accessible-accordion';

import { getStores } from '@string/actions/get-stores';
import { AccordionItemString } from '@string/components/accordion-item-string/accordion-item-string';
import { useLocale } from '@string/hooks/use-locale/use-locale';
import type { Country } from '@string/types/portal-types/country';
import type { Store } from '@string/types/portal-types/store';

import styles from './find-store-filters.module.scss';

interface FindStoreFiltersProps {
  countryLabel: string;
  countries: Country[];
  locationsLabel: string;
  allProductsLabel: string;
  linkLabel: string;
}

export const FindStoreFilters: React.FC<FindStoreFiltersProps> = memo(
  ({ countryLabel, locationsLabel, allProductsLabel, countries }) => {
    const uid = useId();
    const locale = useLocale();
    const [country, setCountry] = useState<Country | null>(null);
    const [stores, setStores] = useState<Store[] | null>(null);
    const count = useMemo(() => `(${stores?.length || 0})`, [stores]);
    const [assortments, setAssortments] = useState<string[] | null>(null);
    const [selectedAssortment, setSelectedAssortment] =
      useState<string>(allProductsLabel);

    useEffect(() => {
      if (countries && locale) {
        const country = countries.find(
          (country) => country.code === locale.country_code.toUpperCase()
        );
        if (country) {
          setCountry(country);
        }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (stores) {
        const assortments = stores.reduce((acc, store) => {
          store.assortments.forEach((assortment) => {
            if (!acc.includes(assortment)) {
              acc.push(assortment);
            }
          });
          return acc;
        }, [] as string[]);
        setAssortments(assortments);
      }
    }, [stores]);

    function handleCountryClick(country: Country) {
      setCountry(country);
    }

    function handleCountryChange(countryCode: string) {
      getStores(locale, countryCode).then((storeList) => {
        if (storeList?.stores) {
          const sortedStores = [...storeList.stores].sort((a, b) =>
            (a.city || a.name) > (b.city || b.name) ? 1 : -1
          );
          setStores(sortedStores);
        }
      });
    }

    function handleAssortmentClick(assortment: string) {
      setSelectedAssortment(assortment);
    }

    useEffect(
      () => {
        if (country?.code) {
          handleCountryChange(country.code);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [country]
    );

    function storeFilter(store: Store) {
      if (selectedAssortment === allProductsLabel) {
        return true;
      }
      return store.assortments.includes(selectedAssortment);
    }

    function storeMapper({
      name,
      street,
      zip,
      city,
      webshop,
      url = '',
    }: Store) {
      const zipCity = [zip, city].join(' ');
      const addressLine = 'address-line';
      const key = `FindStoreFilters-${uid}-${
        name + street + zip + city + webshop + url
      }`;
      return (
        <li key={key} className={styles.store}>
          <div className={clsx(styles.address)}>
            <span className={styles[addressLine]}>{name}</span>
            <span className={styles[addressLine]}>{street}</span>
            <span className={styles[addressLine]}>{zipCity}</span>
          </div>
          {webshop && url && (
            <Link className={clsx('text__small', styles.link)} href={url}>
              <span>Visit homepage</span>
            </Link>
          )}
        </li>
      );
    }

    const assortmentMapper = (assortment: string, index: number) => {
      if (!assortment) {
        return null;
      }
      const onClick = () => handleAssortmentClick(assortment);
      const onKeyDown: React.KeyboardEventHandler<HTMLLIElement> = (event) => {
        if (event.key === 'Enter' || event.key === ' ') {
          handleAssortmentClick(assortment);
        }
      };
      return (
        <li
          key={`Filters-assortment-${assortment}-${index}`}
          className={clsx(
            styles['list--item'],
            selectedAssortment === assortment && styles['list--item-active']
          )}
          onClick={onClick}
          onKeyDown={onKeyDown}
        >
          {assortment}
        </li>
      );
    };

    const countryMapper = (item: Country, index: number) => {
      if (!item) {
        return null;
      }
      const onClick = () => handleCountryClick(item);
      const onKeyDown: React.KeyboardEventHandler<HTMLLIElement> = (event) => {
        if (event.key === 'Enter' || event.key === ' ') {
          handleCountryClick(item);
        }
      };
      return (
        <li
          key={`Filters-country-${item.code}-${index}`}
          className={clsx(
            styles['list--item'],
            item === country && styles['list--item-active']
          )}
          onClick={onClick}
          onKeyDown={onKeyDown}
        >
          {item.name}
        </li>
      );
    };

    return (
      <Accordion
        className={clsx('grid', styles.root)}
        allowMultipleExpanded
        allowZeroExpanded
      >
        <AccordionItemString
          className={clsx(styles.accordion, styles.assortment)}
          title={selectedAssortment}
        >
          <ul className={clsx(styles.inner2, styles.list, 'text__body')}>
            {assortments?.map(assortmentMapper)}
          </ul>
        </AccordionItemString>
        <AccordionItemString
          className={clsx(styles.accordion, styles.country)}
          title={country?.name ?? countryLabel}
        >
          <ul className={clsx(styles.inner2, styles.list, 'text__body')}>
            {countries?.map(countryMapper)}
          </ul>
        </AccordionItemString>
        <AccordionItemString
          className={clsx(styles.accordion, styles.stores)}
          smallLabel={count}
          title={locationsLabel}
          uuid="stores"
        >
          <ul className={clsx(styles.inner2, styles.store__list, 'text__body')}>
            {stores?.filter(storeFilter).map(storeMapper)}
          </ul>
        </AccordionItemString>
      </Accordion>
    );
  }
);
