import { type ImageLoader } from 'next/image';

import {
  getStoryblokImageSrc,
  parseStoryblokImageSrc,
} from '../get-storyblok-image-src/get-storyblok-image-src';

export function srcIsStoryblok(src: string): boolean {
  const url = new URL(src);
  return url.hostname.includes('storyblok');
}

export function getStoryblokSrcPath(src: string): string {
  const url = new URL(src);
  const parts = url.pathname.split('/');
  return parts.slice(0, 6).join('/');
}

export const storyblokLoader: ImageLoader = ({
  src,
  width: inputWidth,
  quality,
}) => {
  const url = new URL(src);

  const parsed = parseStoryblokImageSrc(src);

  const parts = url.pathname.split('/');
  const size = parts[3].split('x');
  const originalWidth = parseInt(size[0], 10);
  const originalHeight = parseInt(size[1], 10);

  const originalAspectRatio = originalWidth / originalHeight;
  let resizedAspectRatio = originalAspectRatio;

  if (parsed?.resize && parsed.resize.height) {
    resizedAspectRatio = parsed.resize.width / parsed.resize.height;
  }

  const resize = {
    height: resizedAspectRatio
      ? Math.floor(inputWidth / resizedAspectRatio)
      : 0,
    width: inputWidth,
  };

  return (
    getStoryblokImageSrc(src, {
      quality,
      resize,
    }) || src
  );
};
