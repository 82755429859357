import { useContext } from 'react';

import { ModalContext, ModalContextValue } from './modal-context';

export const useModal = (): ModalContextValue => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within ModalContext!');
  }
  return context;
};
