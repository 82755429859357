import React, { forwardRef, useId, useMemo } from 'react';

import clsx from 'clsx';

import styles from './input-text.module.scss';

export interface InputTextProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  type?: 'text';
  message?: string;
  simple?: boolean;
  inputClassName?: string;
  labelClassName?: string;
  messageClassName?: string;
  style?: React.CSSProperties;
}

export const InputText = forwardRef<HTMLInputElement, InputTextProps>(
  (
    {
      label,
      placeholder,
      className,
      type = 'text',
      message,
      simple,
      inputClassName,
      labelClassName,
      messageClassName,
      style: styleInput,
      ...props
    },
    ref
  ) => {
    const uid = useId();
    const id = useMemo(() => `input-text-${uid}`, [uid]);
    const style = {
      ...styleInput,
      '--input-padding-inline': simple ? 0 : '1fr',
    } as React.CSSProperties;
    return (
      <div
        className={clsx(styles.root, simple && styles.simple, className)}
        style={style}
      >
        <input
          ref={ref}
          className={clsx(styles.input, inputClassName)}
          id={id}
          placeholder={label ?? placeholder}
          type={type}
          {...props}
        />
        <label
          className={clsx('text__label', styles.label, labelClassName)}
          htmlFor={id}
        >
          {label ?? placeholder}
        </label>
        <div className={clsx('text__small', styles.message, messageClassName)}>
          {message}
        </div>
      </div>
    );
  }
);
