import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  HTMLAttributes,
} from 'react';

import Link from 'next/link';

import clsx from 'clsx';

import type { ThemeVariant } from '@string/types';

import styles from './button-text-link.module.scss';

export interface ButtonTextLinkProps
  extends HTMLAttributes<HTMLElement>,
    Pick<
      AnchorHTMLAttributes<HTMLAnchorElement>,
      'href' | 'target' | 'download'
    >,
    Pick<ButtonHTMLAttributes<HTMLButtonElement>, 'disabled'> {
  variant?: ThemeVariant | 'filled';
  wide?: boolean;
}

export const ButtonTextLink: React.FC<ButtonTextLinkProps> = ({
  children,
  variant = 'filled',
  wide,
  href,
  ...rest
}) => {
  const className = clsx(
    'text__small',
    styles['root'],
    variant === 'filled' && styles['filled'],
    variant === 'dark' && styles['dark'],
    variant === 'light' && styles['light'],
    wide && styles['wide'],
    rest.className
  );
  const props = {
    ...rest,
    className,
  };
  if (href) {
    return (
      <Link href={href} {...props}>
        {children}
      </Link>
    );
  }
  return <button {...props}>{children}</button>;
};
