export function rewriteAssetUrl(input: string, hostname: string): string {
  const url = new URL(input);
  if (url.hostname === 'download.icatserver.io') {
    return input.replace(
      'https://download.icatserver.io/',
      `https://${hostname}/assets/icat/`
    );
  }
  if (url.hostname === 'a.storyblok.com') {
    return input.replace(
      'https://a.storyblok.com/',
      `https://${hostname}/assets/sb/`
    );
  }
  return input;
}
