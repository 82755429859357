'use client';
import { useState } from 'react';

import Link from 'next/link';

import clsx from 'clsx';

import { StringImage } from '@string/components/string-image/string-image';
import type { ProductCardDetails } from '@string/types/product-card-details';
import type { SiteLocale } from '@string/types/site';
import { formatPrice } from '@string/utils/price/format-price';

import { Badge } from '../badge/badge';

import styles from './product-list-card.module.scss';

interface ProductListCardProps {
  product: ProductCardDetails;
  locale: SiteLocale;
  newLabel: string;
  className: string;
  environmentImage: boolean;
  big?: boolean;
  style?: React.CSSProperties;
  priority?: boolean;
  sizes?: string;
}

export const ProductListCard: React.FC<ProductListCardProps> = ({
  product,
  locale,
  newLabel,
  environmentImage,
  big,
  className,
  style,
  priority,
  sizes,
}) => {
  const [isHover, setIsHover] = useState(big);
  const { title, product_image, price, is_news, href = '' } = product;
  const priceString = formatPrice(price, locale.localeTag);
  const alt = product_image?.description ?? title ?? 'Product image';
  const image = product_image?.url;
  const hoverImage = product.environmentImage?.url;
  const currentImage = (isHover || big) && hoverImage ? hoverImage : image;

  function onMouseEnter() {
    if (!hoverImage || environmentImage) {
      return;
    }
    setIsHover(true);
  }

  function onMouseLeave() {
    if (environmentImage) {
      return;
    }
    setIsHover(false);
  }

  return (
    <Link
      className={clsx(className, styles.root)}
      href={href}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={style}
    >
      <figure className={clsx(styles.figure, isHover && styles.hover)}>
        {is_news && <Badge color="blue" text={newLabel} />}
        {currentImage && (
          <StringImage
            alt={alt}
            priority={priority}
            sizes={sizes}
            src={currentImage}
            fill
          />
        )}
      </figure>
      <div className={styles.text}>
        <p className="text__body">{title}</p>
        <span className={clsx('text__body', styles.price)}>{priceString}</span>
      </div>
    </Link>
  );
};
