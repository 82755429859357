import { useId } from 'react';

import clsx from 'clsx';

import { StringImage } from '@string/components/string-image/string-image';
import type { OverlayDetails } from '@string/types';
import { getSizes } from '@string/utils/image/get-sizes';

import styles from './document-list-overlay.module.scss';

interface DocumentListOverlayProps {
  overlay: OverlayDetails;
  handleOverlay: (value: OverlayDetails | null) => void;
  downloadLabel: string;
  onDownloadClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  children?: React.ReactNode;
}

export const DocumentListOverlay: React.FC<DocumentListOverlayProps> = ({
  overlay,
  handleOverlay,
  downloadLabel,
  children,
}) => {
  const uid = useId();

  const linkMapper = (href: string, i: number, all: string[]) => {
    const suffix = all.length > 1 ? ` (${i + 1})` : '';
    const key = `DocumentListOverlay-${uid}-${href}`;
    const url = new URL(href);
    const external = !url.pathname.startsWith('/assets/');
    // This needs to use a tag and not next/link for download to work
    return (
      <a
        key={key}
        className={clsx('text__small', 'text__link')}
        download={!external}
        href={href}
        rel={external ? 'noreferrer' : undefined}
        target={external ? '_blank' : undefined}
      >
        {downloadLabel}
        {suffix}
      </a>
    );
  };
  const { alt, src } = overlay;
  const sizes = getSizes([
    [0, '100vw'],
    [768, '800px'],
  ]);
  const onClick: React.MouseEventHandler<HTMLElement> = (event) => {
    if (event.target === event.currentTarget) {
      handleOverlay(null);
    }
  };
  const onKeyDown: React.KeyboardEventHandler<HTMLElement> = (event) => {
    if (
      (event.key === 'Enter' || event.key === ' ') &&
      event.target === event.currentTarget
    ) {
      handleOverlay(null);
    }
  };
  return (
    <div className={styles.overlay} onClick={onClick} onKeyDown={onKeyDown}>
      <div className={clsx(styles.card, styles[overlay.orientation])}>
        <figure className={clsx(styles.figure, styles[overlay.orientation])}>
          <StringImage
            alt={alt}
            className={styles.image}
            sizes={sizes}
            src={src}
          />
        </figure>
        <div className={styles.details}>
          <div className={styles.header}>
            <p className={styles.title}>{overlay.title}</p>
            <div className={styles.list}>{overlay.hrefs?.map(linkMapper)}</div>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};
